import React from "react";

import { useColor } from "../../hooks/useColor";

export function LogoIcon({ color, ...props }: Application.IconProps) {
  const iconColor = useColor({ light: (palette) => color || palette.total.main });

  return (
    <svg
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9263 3.36485C11.7468 3.41026 11.5844 3.53738 11.4648 3.72807C11.3665 3.87335 11.3665 3.90059 11.3665 5.23087V6.58838H12.1357H12.905V5.79385V4.99932H24.0164H35.1278V16.8038V28.6082H24.0164H12.905V20.0273V11.4464H12.1357H11.3665V20.6402C11.3665 30.7512 11.3451 30.0247 11.6229 30.1746C11.734 30.2336 13.1315 30.2427 24.0164 30.2427C34.9013 30.2427 36.2988 30.2336 36.4099 30.1746C36.6877 30.0202 36.6663 31.1371 36.6663 16.8038C36.6663 2.5567 36.6877 3.6191 36.4227 3.42842C36.3073 3.34215 36.0125 3.34215 24.1873 3.33307C17.5248 3.33307 12.0075 3.34669 11.9263 3.36485Z"
        fill={iconColor}
      />
      <path
        d="M22.907 11.465C22.907 11.4741 22.5523 13.844 22.1164 16.7316C21.6805 19.6191 21.3258 22.0118 21.3258 22.0481C21.3258 22.1071 21.4369 22.1162 22.0651 22.1162H22.8045L22.9284 21.1628L23.0524 20.2093H24.0054H24.9541L24.9798 20.3546C25.0225 20.6316 25.2148 22.0209 25.2148 22.0663C25.2148 22.0981 25.4627 22.1162 25.9627 22.1162C26.5311 22.1162 26.7106 22.1026 26.7106 22.0572C26.7106 22.0299 26.3473 19.6327 25.9071 16.7361L25.1037 11.4695L24.0054 11.4559C23.4028 11.4514 22.907 11.4514 22.907 11.465ZM24.3601 16.0369C24.5481 17.3717 24.7148 18.5159 24.7276 18.584L24.7533 18.7111H24.0225C23.6207 18.7111 23.2917 18.6975 23.2917 18.6748C23.2874 18.6112 23.9968 13.5852 24.0054 13.5989C24.0139 13.6034 24.172 14.7021 24.3601 16.0369Z"
        fill={iconColor}
      />
      <path
        d="M3.60296 13.1711C3.31236 13.3573 3.33373 12.3357 3.33373 24.8711C3.33373 36.04 3.33373 36.2125 3.4192 36.3578C3.46193 36.4395 3.5645 36.5394 3.64143 36.5848C3.77818 36.662 4.2611 36.6665 14.4537 36.6665H25.1249L25.2403 36.5712C25.3044 36.5212 25.3984 36.4213 25.4455 36.3532C25.5309 36.2306 25.5352 36.1716 25.548 34.0287L25.5608 31.8312H24.7659H23.9753V33.4203V35.0093H14.4238H4.87223V24.8621V14.7148H7.3723H9.87237V13.8975V13.0803H6.80818C3.84229 13.0803 3.73545 13.0849 3.60296 13.1711Z"
        fill={iconColor}
      />
    </svg>
  );
}
