import { Action, ThunkDispatch } from "@reduxjs/toolkit";

import { logout } from "../slices/authSlice";
import { RootState } from "../store/configureStore";
import { identityObject, normalizePhone } from "../utils";
import { resetBriefcaseSlice } from "../slices/briefcaseSlice";
import { changeFinanceSecurityBackdropShown } from "../slices/applicationSlice";

export function formatAuthenticateBody(
  values: Auth.AuthenticateBodyProps,
): Auth.AuthenticateBodyProps {
  return identityObject({
    ...values,
    phone: normalizePhone(values.phone),
  });
}

export function authLogout(dispatch: ThunkDispatch<RootState, object, Action>) {
  // Очистка сессии
  dispatch(logout());
  dispatch(changeFinanceSecurityBackdropShown(false));
  // Очистка срезов
  dispatch(resetBriefcaseSlice());
}
