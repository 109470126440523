import Hex from "crypto-js/enc-hex";
import SHA256 from "crypto-js/sha256";
import HmacSHA256 from "crypto-js/hmac-sha256";

interface GenerateSignatureProps {
  readonly url: string;
  readonly code?: string;
}

export function generateSignature({ code = "", url }: GenerateSignatureProps): string {
  const preSharedKey = code.repeat(16);

  const urlHash = SHA256(url).toString(Hex);
  const preSharedKeyHash = SHA256(preSharedKey).toString(Hex);

  return HmacSHA256(urlHash, preSharedKeyHash).toString(Hex);
}
