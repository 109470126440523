import { Route, Routes } from "react-router-dom";

import { AuthPage } from "../pages/start/AuthPage";
import { AppRoutes } from "../constants/AppRoutes";
import { SplashPage } from "../pages/start/SplashPage";
import { StartLayout } from "../components/app/StartLayout";
import { AgreementPage } from "../pages/start/AgreementPage";

export default function StartStack() {
  return (
    <Routes>
      <Route path="/" element={<StartLayout />}>
        <Route index={true} element={<SplashPage />} />
        <Route path={AppRoutes.StartAuth} element={<AuthPage />} />
        <Route path={AppRoutes.StartAgreement} element={<AgreementPage />} />
      </Route>
    </Routes>
  );
}
