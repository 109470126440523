import { createApi } from "@reduxjs/toolkit/query/react";

import { createUrl } from "../utils";
import { ApiRoutes } from "../constants/ApiRoutes";
import { ApiMethod, fetchQuery } from "../helpers/ApiHelpers";

export const surveyApi = createApi({
  reducerPath: "surveyApi",
  baseQuery: fetchQuery(),
  endpoints: (builder) => ({
    generateSurveyCode: builder.mutation<void, Pick<Survey.Props, "types">>({
      query: (args) => ({
        url: createUrl(ApiRoutes.SurveyGenerateCode, { query: { type: args.types } }),
      }),
    }),
    getSurveyData: builder.query<
      Application.ResponseProps<Survey.Props>,
      Survey.GetSurveyRequestProps
    >({
      query: (args) => createUrl(ApiRoutes.SurveyData, { query: { type: args.types } }),
    }),
    sendSurveyData: builder.mutation<void, Survey.BodyProps>({
      query: (args) => ({
        url: ApiRoutes.SurveyData,
        method: ApiMethod.Post,
        body: args,
      }),
    }),
  }),
});

export const { useGetSurveyDataQuery, useSendSurveyDataMutation, useGenerateSurveyCodeMutation } =
  surveyApi;
