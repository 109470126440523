import { useTheme } from "@mui/styles";
import { Theme } from "@mui/material/styles";

interface OptionsProps {
  readonly dark?: (palette: Theme["palette"]) => string;
  readonly light: (palette: Theme["palette"]) => string;
}

export function useColor({ light, dark }: OptionsProps): string {
  const theme = useTheme<Theme>();

  if (theme.palette.mode === "dark" && dark) {
    return dark(theme.palette);
  }

  return light(theme.palette);
}
