import React from "react";
import { useColor } from "../../hooks/useColor";

export function CloseIcon({ color, size = 30, ...props }: Application.IconProps) {
  const crossColor = useColor({ light: (palette) => color || palette.gary.main });
  const backgroundColor = useColor({ light: (palette) => palette.wall.main });
  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9650_58119)">
        <g filter="url(#filter0_b_9650_58119)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
            fill={backgroundColor}
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.6263 9.62534C20.1538 9.15286 19.3877 9.15292 18.9152 9.62548L14.9235 13.6172L11.0081 9.70187C10.5357 9.22939 9.76957 9.22946 9.29701 9.70202C8.82445 10.1746 8.82439 10.9407 9.29687 11.4132L13.2122 15.3285L9.22198 19.3187C8.74942 19.7912 8.74936 20.5573 9.22184 21.0298C9.69432 21.5023 10.4604 21.5022 10.933 21.0297L14.9232 17.0395L18.837 20.9533C19.3095 21.4258 20.0756 21.4257 20.5482 20.9532C21.0207 20.4806 21.0208 19.7145 20.5483 19.242L16.6345 15.3282L20.6262 11.3365C21.0987 10.8639 21.0988 10.0978 20.6263 9.62534Z"
          fill={crossColor}
        />
      </g>
      <defs>
        <filter
          id="filter0_b_9650_58119"
          x="-54.3656"
          y="-54.3656"
          width="138.731"
          height="138.731"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="27.1828" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_9650_58119" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_9650_58119"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_9650_58119">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
