import { PaletteMode } from "@mui/material";
import { ThemeMode } from "../dto/ApplicationDTO";

export function getSystemPaletteMode(name?: PaletteMode): Application.PaletteModeType {
  if (!name && window.matchMedia) {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    if (mediaQuery.matches) {
      return ThemeMode.Dark;
    }
  }

  if (name === "dark") {
    return ThemeMode.Dark;
  }

  return ThemeMode.Light;
}

export function getThemeMode(theme?: Application.PaletteModeType): Application.PaletteModeType {
  if (!theme || theme === ThemeMode.System) {
    return getSystemPaletteMode();
  }

  if (theme === ThemeMode.Dark) {
    return ThemeMode.Dark;
  }

  return ThemeMode.Light;
}

export function formatThemeTitle(theme: Application.PaletteModeType): string {
  switch (theme) {
    case ThemeMode.Dark:
      return "Темная";
    case ThemeMode.Light:
      return "Светлая";
    default:
      return "Системая";
  }
}
