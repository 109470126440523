import React from "react";
import cx from "classnames";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";

import { makeStyles, useTheme } from "../../theme/Theme";

interface StylesProps {
  readonly size: number;
  readonly color: string;
}

const useStyle = makeStyles({
  progress: {
    color: (props: StylesProps) => props.color,
  },
});

interface Props extends Omit<CircularProgressProps, "size" | "color"> {
  readonly size?: number;
  readonly color?: string;
}

export function Loader({ size = 24, color, className, ...props }: Props) {
  const theme = useTheme();

  const classes = useStyle({ size, color: color || theme.palette.bloom.main });

  return (
    <CircularProgress
      color="inherit"
      {...props}
      size={size}
      data-testid="LoaderTestId"
      className={cx(classes.progress, className)}
    />
  );
}
