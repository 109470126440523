import React, { PropsWithChildren, useCallback, useContext, useState } from "react";
import {
  NotificationContextProps,
  Snackbar,
  SnackbarData,
  SnackbarInitialState,
  SnackbarProps,
} from "../ui/Snackbar";
import { getErrorTextFromError } from "../../utils";

export const NotificationContext = React.createContext<NotificationContextProps | null>(null);

export function NotificationProvider(props: PropsWithChildren<unknown>) {
  const [notificationState, setNotificationState] = useState<SnackbarData>(SnackbarInitialState);

  const closeSnackbar = useCallback(() => {
    setNotificationState((state) => ({ ...state, open: false }));
  }, [setNotificationState]);

  const openSnackbar = useCallback(
    (data: SnackbarProps) => {
      setNotificationState({ ...data, open: true });
    },
    [setNotificationState],
  );

  return (
    <NotificationContext.Provider
      {...props}
      value={{ ...notificationState, openSnackbar, closeSnackbar }}
    >
      {props.children}
      <Snackbar
        open={notificationState.open}
        title={notificationState.title}
        content={notificationState.content}
        openSnackbar={openSnackbar}
        closeSnackbar={closeSnackbar}
        variant="info"
      />
    </NotificationContext.Provider>
  );
}

export function useSnackbar() {
  const context = useContext(NotificationContext)!;

  const openSnackbarError = useCallback(
    (error: any | undefined = undefined) => {
      const errorText = getErrorTextFromError(error) || "Пожалуйста, попробуйте ещё раз.";
      context.openSnackbar({
        content: errorText,
        title: "Что-то пошло не так ...",
      });
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [context.openSnackbar],
  );

  return {
    openSnackbar: context.openSnackbar,
    closeSnackbar: context.closeSnackbar,
    openSnackbarError,
  };
}
