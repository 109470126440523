import { useDispatch } from "react-redux";
import { ruRU } from "@mui/material/locale";
import { PaletteMode } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ReactNode, useEffect, useMemo } from "react";

import { ThemeMode } from "../dto/ApplicationDTO";
import { profileSelector } from "../api/profileApi";
import { getThemeMode } from "../helpers/ApplicationHelpers";
import { createAppTheme, useIsDarkTheme } from "../theme/Theme";
import { useShallowEqualSelector } from "../hooks/useShallowEqualSelector";
import { changeThemeMode, themeModeSelector } from "../slices/profileSlice";

interface Props {
  readonly children: ReactNode;
}

export function ThemeContainer({ children }: Props) {
  const isDarkTheme = useIsDarkTheme();
  const dispatch = useDispatch();

  const profile = useShallowEqualSelector(profileSelector);
  const themeMode = useShallowEqualSelector(themeModeSelector(profile?.id));

  const theme = getThemeMode(themeMode.mode);

  const appTheme = useMemo(() => createTheme(createAppTheme(theme as PaletteMode), ruRU), [theme]);

  useEffect(() => {
    if (themeMode.state === ThemeMode.System) {
      const mode = isDarkTheme ? "dark" : "light";

      dispatch(changeThemeMode({ data: { mode }, id: profile?.id }));
    }
  }, [dispatch, themeMode, isDarkTheme, profile?.id]);

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />

      {children}
    </ThemeProvider>
  );
}
