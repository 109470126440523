import { toFinite, toNumber, toString, toUpper } from "lodash";

export function formatCurrencyNumber(value: string | number = 0): string {
  return toNumber(value)
    .toString()
    .replace(/\./g, ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function formatPhoneToString(phone = "", options?: { forUrl: boolean }): string {
  const tmpPhone = toString(phone).replace(/[()\s-]/g, "");

  if (options && options.forUrl) {
    return tmpPhone.replace("+", "%2B");
  }

  return tmpPhone;
}

export function declineNumbered(number: number, titles: [string, string, string]): string {
  const cases = [2, 0, 1, 1, 1, 2];

  return titles[
    number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
  ];
}

export function getRandomInt(min: number, max = 1000000) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function maskPhoneNumber(phoneNumber = ""): string {
  if (phoneNumber.trim() === "+" || phoneNumber.trim() === "+7" || phoneNumber.trim() === "+7 (") {
    return phoneNumber;
  }

  if (phoneNumber.trim() === "7" || phoneNumber.trim() === "8") {
    return "+7";
  }

  const phone = phoneNumber.replace(/\D/g, "").slice(0, 12);

  const matchPhone = phone
    .replace(/\D/g, "")
    .replace(/^\+?([78])?/, "")
    .match(/^(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

  if (matchPhone) {
    if (matchPhone[1] && !matchPhone[2]) {
      return `+7 (${matchPhone[1]}`;
    }

    if (matchPhone[2]) {
      const number = matchPhone.slice(2, 5).filter(Boolean).join(" ");

      return `+7 (${matchPhone[1]}) ${number}`;
    }

    return ``;
  }

  return phoneNumber;
}

export function formatWight(weight = 0): string {
  if (weight <= 0) {
    return "";
  }

  if (weight >= 1000) {
    return `${weight / 1000} кг`;
  }

  return `${weight} гр`;
}

interface FormatPriceOptionsProps {
  readonly showZero?: boolean;
  readonly zeroAsFree?: boolean;
}

const FORMAT_PRICE_DEFAULT_OPTIONS = { showZero: true, zeroAsFree: false };

export function formatPrice(
  price?: number | string,
  { showZero, zeroAsFree }: FormatPriceOptionsProps = FORMAT_PRICE_DEFAULT_OPTIONS,
): string {
  if (!showZero && !price) {
    return "";
  }

  if (zeroAsFree && !price) {
    return "бесплатно";
  }

  return `${formatCurrencyNumber(toFinite(price) / 100)} ₽`;
}

export function normalizePhone(phone = "", prefix?: string): string {
  const normalizedPhone = phone.replace(/\D/g, "").slice(0, 11);

  const isFreeCall = normalizedPhone.startsWith("8800");

  if (isFreeCall) {
    return normalizedPhone;
  }

  return [prefix, normalizedPhone].filter(Boolean).join("");
}

interface FormatHexToRGBAProps {
  readonly color: string;
  readonly alpha?: number;
  readonly fallbackColor?: string;
}

export function formatHexToRGBA({
  color,
  alpha = 1,
  fallbackColor = "black",
}: FormatHexToRGBAProps): string {
  const matchColor = color?.match(/^#([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/);

  if (matchColor) {
    const first = parseInt(matchColor[1], 16);
    const second = parseInt(matchColor[2], 16);
    const third = parseInt(matchColor[3], 16);

    return `rgba(${first},${second},${third},${alpha})`;
  }

  return fallbackColor;
}

export function formatNumberPostfix(value: number | string) {
  const num = toFinite(value);

  const signNum = Math.sign(num);
  const absoluteNum = Math.abs(num);
  const floatNum = toFinite((absoluteNum / 1000).toFixed(1));

  const res = absoluteNum > 999 ? signNum * floatNum : signNum * absoluteNum;

  return formatCurrencyNumber(res) + " k";
}

export function formatToPercentage(summa: number, value: number) {
  const ratio = summa / value;

  return 100 / ratio;
}

export function normalizeUrl(url?: string): string | undefined {
  if (url != null) {
    if (url.startsWith("http")) {
      return url;
    }

    return `https://${url}`;
  }
}

export const toShortPatronymic = (patronymic?: string): string =>
  patronymic ? `${toUpper(patronymic.slice(0, 1))}.` : "";

export function maskString(inputString: string | undefined, visibleCharsFromEnd: number): string {
  if (!inputString) {
    return "";
  }
  const strLength = inputString?.length;
  const maskedPart = "*".repeat(Math.max(0, strLength - visibleCharsFromEnd));
  const visiblePart = inputString?.substring(strLength - visibleCharsFromEnd);

  return `${maskedPart}${visiblePart}`;
}
