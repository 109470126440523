import { KeyboardEvent } from "react";

function filterLettersKeepSpacesAndPunctuation(text: string): string {
  return text.replace(/[^a-zA-Z\s\u0400-\u04FF]/gu, "");
}

export function filterDigits(text: string): string {
  return text.replace(/[^0-9+]/gu, "");
}
export const validateField = (
  event: KeyboardEvent<HTMLDivElement>,
  type: Survey.InputCharactersType,
) => {
  const target = event.nativeEvent.target;

  if (type === "only-letters") {
    // @ts-ignore
    const letterValue = filterLettersKeepSpacesAndPunctuation(target.value);
    // @ts-ignore
    target.value = letterValue;
  }

  if (type === "only-numbers") {
    // @ts-ignore
    const letterValue = filterDigits(target.value);
    // @ts-ignore
    target.value = letterValue;
  }
};

export const limitByLength = (input: HTMLInputElement, limit: string) =>
  input?.setAttribute("maxlength", limit);
