import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";

import { makeStyles } from "../../theme/Theme";
import { BackButton } from "../buttons/BackButton";
import { Breakpoints } from "../../dto/ApplicationDTO";
import { hidePhoneNumbers } from "../../helpers/FormatHelpers";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", flexDirection: "column" },
  backButton: {
    marginBottom: theme.typography.pxToRem(20),
  },
  subTitle: {
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.only(Breakpoints.Mobile)]: {
      marginTop: theme.typography.pxToRem(10),
    },
    [theme.breakpoints.up(Breakpoints.Mobile)]: {
      marginTop: theme.typography.pxToRem(15),
    },
  },
}));

const TITLE = "Войти\nв личный кабинет";

export interface AuthFormHeaderProps {
  readonly phone?: string;
  readonly onBackClick: () => void;
}

export function AuthFormHeader({ phone, onBackClick }: AuthFormHeaderProps) {
  const classes = useStyles();

  const title = phone ? "Введите код из СМС" : TITLE;

  const formattedPhone = useMemo(() => {
    if (phone) {
      return hidePhoneNumbers(phone);
    }
  }, [phone]);

  return (
    <div className={classes.root}>
      {!!phone && <BackButton onClick={onBackClick} className={classes.backButton} />}

      <Typography whiteSpace="pre-wrap" variant="h1">
        {title}
      </Typography>

      {!!phone && (
        <div className={classes.subTitle}>
          <Typography variant="text1">Мы отправили СМС на Ваш номер:</Typography>
          <Typography variant="text1">{formattedPhone}</Typography>
        </div>
      )}
    </div>
  );
}
