import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchQuery } from "../helpers/ApiHelpers";
import { ApiRoutes } from "../constants/ApiRoutes";
import { createUrl } from "../utils";

export const qrCodeApi = createApi({
  baseQuery: fetchQuery(),
  reducerPath: "qrCodeApi",
  endpoints: (build) => ({
    getQrCodeAuth: build.query<
      Application.ResponseProps<QrCode.GetQrCodeAuthResponse>,
      QrCode.GetQrCodeAuthRequest
    >({
      query: (data) => createUrl(ApiRoutes.GetQrCodeAuth, { query: data }),
    }),
    checkQrCodeAuth: build.query<
      Application.ResponseProps<QrCode.CheckQrCodeAuthResponse>,
      QrCode.CheckQrCodeAuthRequest
    >({
      query: (data) => {
        return {
          url: createUrl(ApiRoutes.CheckQrCodeAuth, {
            query: { qrCodeContent: data.qrCodeContent },
          }),
        };
      },
    }),
  }),
});

export const {
  useCheckQrCodeAuthQuery,
  useLazyGetQrCodeAuthQuery,
  useLazyCheckQrCodeAuthQuery,
  useGetQrCodeAuthQuery,
} = qrCodeApi;
