import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/configureStore";

const initialState = {
  isShowReplenishSbp: true,
  isShowPeriodSbp: true,
};

export const replenishSlice = createSlice({
  name: "replenishSlice",
  initialState,
  reducers: {
    changeShowReplenish: (state, action: PayloadAction<boolean>) => {
      state.isShowReplenishSbp = action.payload;
    },
    changeShowPeriodSpb: (state, action: PayloadAction<boolean>) => {
      state.isShowPeriodSbp = action.payload;
    },
  },
});

export const { changeShowReplenish, changeShowPeriodSpb } = replenishSlice.actions;
export const isShowReplenishSbpSelector = (state: RootState) =>
  state.replenishSlice.isShowReplenishSbp;
export const isShowPeriodReplenishSpbSelector = (state: RootState) =>
  state.replenishSlice.isShowPeriodSbp;
