import React from "react";
import cx from "classnames";
import Typography from "@mui/material/Typography";
import { ButtonBase, ButtonBaseProps } from "@mui/material";

import { ChevronIcon } from "../icons/ChevronIcon";
import { makeStyles, useTheme } from "../../theme/Theme";

const useStyles = makeStyles((theme) => ({
  title: { color: theme.palette.gary.main },
  root: { display: "flex", alignItems: "center", cursor: "pointer" },
  icon: { marginRight: theme.typography.pxToRem(10), marginLeft: theme.typography.pxToRem(-6) },
}));

interface Props extends Omit<ButtonBaseProps, "children"> {
  color?: string;
}

export function BackButton({ className, color, ...props }: Props) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div>
      <ButtonBase disableRipple={true} {...props} className={cx(classes.root, className)}>
        <ChevronIcon color={color ?? theme.palette.gary.main} className={classes.icon} />
        <Typography
          variant="button1"
          className={classes.title}
          color={color ?? theme.palette.gary.main}
        >
          Назад
        </Typography>
      </ButtonBase>
    </div>
  );
}
