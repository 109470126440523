import React from "react";
import cx from "classnames";

import { makeStyles } from "../../theme/Theme";
import { useColor } from "../../hooks/useColor";
import { Direction } from "../../dto/ApplicationDTO";
import { SvgIcon } from "@mui/material";

interface StylesProps {
  direction: Direction;
  disabled: boolean;
  withTransition?: boolean;
}

const useStyles = makeStyles<StylesProps>(() => ({
  root: {
    transition: ({ withTransition }) => `all ${withTransition ? 0.3 : 0}s !important`,
    transform: ({ direction }) => {
      if (direction === Direction.Up) {
        return "rotate(90deg)";
      }

      if (direction === Direction.Right) {
        return "rotate(180deg)";
      }

      if (direction === Direction.Down) {
        return "rotate(270deg)";
      }
    },
    opacity: ({ disabled }) => (disabled ? 0.5 : 1.0),
  },
}));

interface Props extends Application.IconProps {
  direction?: Direction;
  disabled?: boolean;
  withTransition?: boolean;
}

export function ChevronIcon({
  color,
  disabled = false,
  direction = Direction.Left,
  className,
  sx,
  withTransition,
  ...props
}: Props) {
  const styles = useStyles({ direction, withTransition, disabled });

  const iconColor = useColor({ light: (palette) => color || palette.total.main });

  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={cx(styles.root, className)}
      sx={{ fill: "none", ...sx }}
    >
      <g clipPath="url(#clip0_1069_544)">
        <path
          fill={iconColor}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.6006 4.36997C17.1331 4.86326 17.1331 5.66305 16.6006 6.15635L10.2921 12L16.6006 17.8437C17.1331 18.3369 17.1331 19.1367 16.6006 19.63C16.0681 20.1233 15.2047 20.1233 14.6721 19.63L7.3994 12.8932C6.86687 12.3999 6.86687 11.6001 7.3994 11.1068L14.6721 4.36997C15.2047 3.87668 16.0681 3.87668 16.6006 4.36997Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_1069_544">
          <rect width="24" height="24" fill="white" transform="translate(24) rotate(90)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
