import React from "react";

import { LogoWithTextVerticalcon } from "../icons/LogoWithTextVerticalcon";
import { makeStyles } from "../../theme/Theme";

const useStyles = makeStyles({
  root: { display: "flex", flex: 1, alignItems: "center", justifyContent: "center" },
});

export function SuspenseLayout() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LogoWithTextVerticalcon />
    </div>
  );
}
