import { createApi } from "@reduxjs/toolkit/query/react";

import { createUrl } from "../utils/UrlUtils";
import { ApiRoutes } from "../constants/ApiRoutes";
import { fetchQuery } from "../helpers/ApiHelpers";

export const briefcaseApi = createApi({
  reducerPath: "briefcaseApi",
  baseQuery: fetchQuery(),
  keepUnusedDataFor: 30,
  tagTypes: ["Briefcase"],
  endpoints: (builder) => ({
    briefcase: builder.query<
      Application.ResponseProps<Briefcase.Props>,
      Application.DateFilterProps | void
    >({
      providesTags: ["Briefcase"],
      query: (query) => createUrl(ApiRoutes.Briefcase, { query }),
    }),
    briefcaseChart: builder.query<
      Application.ResponseProps<Chart.Props>,
      Application.DateFilterProps | void
    >({
      query: (query) => createUrl(ApiRoutes.BriefcaseChart, { query }),
    }),
    briefcaseStrategy: builder.query<
      Application.ResponseProps<Strategy.StrategyProps>,
      Pick<Application.DateFilterProps, "date"> & Briefcase.StrategyIdProps
    >({
      query: (query) => createUrl(ApiRoutes.BriefcaseStrategy, { query }),
    }),
    briefcaseStrategyChart: builder.query<
      Application.ResponseProps<Chart.Props>,
      Pick<Application.DateFilterProps, "fromDate" | "toDate"> & Briefcase.StrategyIdProps
    >({
      query: (query) => createUrl(ApiRoutes.BriefcaseStrategyChart, { query }),
    }),
    strategyList: builder.query<
      Application.ResponseProps<Strategy.StrategyShortProps[]>,
      { woReserve?: boolean }
    >({
      query: (query) => createUrl(ApiRoutes.BriefcaseStrategyList, { query }),
    }),
  }),
});

export const {
  useBriefcaseQuery,
  useBriefcaseChartQuery,
  useBriefcaseStrategyQuery,
  useBriefcaseStrategyChartQuery,
  useStrategyListQuery,
  useLazyStrategyListQuery,
} = briefcaseApi;
