import { CreateCSSProperties, PropsFunc } from "@mui/styles";
import * as _copy from "copy-to-clipboard";
import { CSSProperties } from "react";

export function copy(data: string) {
  /* @ts-ignore: библиотека написана на чистом JS, проблемы с типами */
  _copy(data);
}

export function on<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T["addEventListener"]> | [string, Function | null, ...any]
): void {
  if (obj && obj.addEventListener) {
    obj.addEventListener(...(args as Parameters<HTMLElement["addEventListener"]>));
  }
}

export function off<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T["removeEventListener"]> | [string, Function | null, ...any]
): void {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(...(args as Parameters<HTMLElement["removeEventListener"]>));
  }
}

export async function readFile(file: File) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.onerror = reject;
    fr.readAsText(file);
  });
}

export function scrollToBottom() {
  setTimeout(() => {
    window.scrollTo({ top: window.document.body.scrollHeight, behavior: "smooth" });
  }, 10);
}

type FileType = "image" | "document";

export function getFileType(file?: File): FileType {
  if (file?.type.includes("image")) {
    return "image";
  }

  return "document";
}

export const pressAreaStyles:
  | CSSProperties
  | CreateCSSProperties<{}>
  | PropsFunc<{}, CreateCSSProperties<{}>> = {
  cursor: "pointer",
  userSelect: "none",
};

export const translationBoolAndNumber = (value: boolean | number | string) => {
  if (typeof value === "number") return Boolean(value);
  if (typeof value === "string") return Boolean(parseInt(value));
  if (typeof value === "boolean") return Number(value);
  return 0;
};
