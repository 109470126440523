import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { AppRoutes } from "../../constants/AppRoutes";
import { isAuthorizedSelector } from "../../slices/authSlice";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";

interface Props {
  readonly component: React.ComponentType;
}

export function PrivateRoute({ component: RouteComponent }: Props) {
  const location = useLocation();

  const isAuthorized = useShallowEqualSelector(isAuthorizedSelector);

  if (isAuthorized) {
    // @ts-ignore
    return <RouteComponent />;
  }

  return <Navigate to={AppRoutes.StartAuth} replace={true} state={{ from: location }} />;
}
