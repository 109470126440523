import React, { useEffect, useState } from "react";

import { ActionButton, ActionButtonProps } from "../ui/ActionButton";

interface Props extends Omit<ActionButtonProps, "children"> {
  readonly phone: string;
}

export function AuthFormButton({ phone, disabled, ...props }: Props) {
  const [time, setTime] = useState(59);

  const buttonDisabled = disabled || (!!phone && time > 0);
  const buttonTitle = phone && time > 0 ? `Новый код через ${time} сек` : "Отправить код повторно";

  useEffect(() => {
    const interval = setInterval(
      () =>
        setTime((x) => {
          if (x > 0) {
            return x - 1;
          }

          return x;
        }),
      1000,
    );

    return () => {
      setTime(59);
      clearInterval(interval);
    };
  }, [phone]);

  return (
    <ActionButton {...props} disabled={buttonDisabled}>
      {buttonTitle}
    </ActionButton>
  );
}
