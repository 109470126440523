import { PersistConfig } from "redux-persist";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../store/configureStore";

export const briefcaseReducerPersistConfig: Partial<PersistConfig<Store.BriefcaseReducerState>> = {
  whitelist: [],
};

const initialState: Store.BriefcaseReducerState = {
  strategies: {},
};

export const briefcaseSlice = createSlice({
  initialState,
  name: "briefcase",
  reducers: {
    setStrategy: (state, action: PayloadAction<Strategy.StrategyShortProps>) => {
      state.strategies[action.payload.id] = action.payload;
    },
    resetBriefcaseSlice: (state) => {
      state.strategies = initialState.strategies;
    },
  },
});

/**
 * Actions
 * */
export const { setStrategy, resetBriefcaseSlice } = briefcaseSlice.actions;

/**
 * Selectors
 * */
export function strategySelector(
  id: string | undefined,
): (state: RootState) => Strategy.StrategyShortProps | undefined {
  return ({ briefcase }) => {
    if (id) {
      return briefcase.strategies[id];
    }
  };
}

/**
 * Reducer
 * */
export default briefcaseSlice.reducer;
