import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { RootContainer } from "./RootContainer";
import { ThemeContainer } from "./ThemeContainer";
import { persistor, store } from "../store/configureStore";
import { NotificationProvider } from "../components/app/NotificationProvider";
import { PrefetchingPrivateContainer } from "./PrefetchingPrivateContainer";

import { CommonComponent } from "../components/CommonComponent";
export function AppContainer() {
  return (
    <Provider store={store}>
      <CommonComponent />
      {/*@ts-ignore*/}
      <PersistGate persistor={persistor}>
        <PrefetchingPrivateContainer />

        <ThemeContainer>
          <NotificationProvider>
            <RootContainer />
          </NotificationProvider>
        </ThemeContainer>
      </PersistGate>
    </Provider>
  );
}
