import { createApi } from "@reduxjs/toolkit/query/react";

import { createUrl } from "../utils/UrlUtils";
import { ApiRoutes } from "../constants/ApiRoutes";
import { fetchQuery } from "../helpers/ApiHelpers";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchQuery(),
  endpoints: (builder) => ({
    sendCode: builder.mutation<Auth.SendCodeResponseProps, Auth.AuthenticateBodyProps>({
      query: (query) => createUrl(ApiRoutes.AuthSendCode, { query }),
      extraOptions: { withSignature: false },
      // @ts-ignore
      transformResponse: ({ sessionid }) => ({ sessionId: sessionid }),
    }),
    checkCode: builder.mutation<
      Application.ResponseProps<Auth.CheckCodeResponseProps>,
      Auth.AuthenticateBodyProps
    >({
      query: (query) => createUrl(ApiRoutes.AuthCheckCode, { query }),
    }),
    logout: builder.mutation<void, void>({ query: () => ApiRoutes.Logout }),
  }),
});

export const { useSendCodeMutation, useLogoutMutation, useCheckCodeMutation } = authApi;
