import { ComponentThemeOverride } from "@mui/material/styles";

export const MuiSwitch: ComponentThemeOverride = {
  styleOverrides: {
    root: ({ theme }) => ({
      width: "auto",
      height: "31px",
      padding: "0px",

      "& > .MuiButtonBase-root": {
        padding: "0px",
        width: "27px",
        height: "27px",
        marginTop: "2px",
        marginLeft: "2px",
        overflow: "hidden",

        "& + .MuiSwitch-track": {
          opacity: 1,
          height: "31px",
          minWidth: "51px",
          borderRadius: "32px",
          backgroundColor: theme.palette.back.main,
        },

        "& > .MuiSwitch-thumb": {
          width: "27px",
          height: "27px",
        },

        "&.Mui-checked": {
          "& > .MuiSwitch-thumb": {
            backgroundColor: theme.palette.const.main,
          },
          "& + .MuiSwitch-track": {
            opacity: 1,
            backgroundColor: theme.palette.bloom.main,
          },
        },

        "&.Mui-disabled": {
          color: theme.palette.const.main,
          "& + .MuiSwitch-track": {
            opacity: 0.5,
          },
        },
      },
    }),
  },
};
