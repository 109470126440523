import {
  useIsMobileDown,
  useIsMobileUp,
  useIsLargeDown,
  useIsLargeUp,
  useIsLaptopDown,
  useIsLaptopUp,
  useIsTabletDown,
  useIsTabletUp,
} from "../theme/Theme";

interface Props<T> {
  readonly downXs?: T;
  readonly upXs?: T;
  readonly downSm?: T;
  readonly upSm?: T;
  readonly downMd?: T;
  readonly upMd?: T;
  readonly downLg?: T;
  readonly upLg?: T;
}

export function useBreakpointValue<T>(
  { upMd, upSm, upXs, upLg, downLg, downMd, downXs, downSm }: Props<T>,
  fallback: T,
): T {
  const isMediumUp = useIsLaptopUp();
  const isMediumDown = useIsLaptopDown();
  const isSmallUp = useIsTabletUp();
  const isSmallDown = useIsTabletDown();
  const isLargeUp = useIsLargeUp();
  const isLargeDown = useIsLargeDown();
  const isExtraSmallUp = useIsMobileUp();
  const isExtraSmallDown = useIsMobileDown();

  if (isLargeUp && upLg) {
    return upLg;
  }

  if (isLargeDown && downLg) {
    return downLg;
  }

  if (isMediumUp && upMd) {
    return upMd;
  }

  if (isMediumDown && downMd) {
    return downMd;
  }

  if (isExtraSmallDown && downXs) {
    return downXs;
  }

  if (isSmallUp && upSm) {
    return upSm;
  }

  if (isSmallDown && downSm) {
    return downSm;
  }

  if (isExtraSmallUp && upXs) {
    return upXs;
  }

  return fallback;
}
