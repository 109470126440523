import { makeStyles } from "../../theme/Theme";
import { AuthFormProps, AuthForm } from "./AuthForm";
import { Breakpoints } from "../../dto/ApplicationDTO";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.flexColumn,
    backgroundColor: theme.palette.wall.main,
    paddingTop: theme.typography.pxToRem(14),
    paddingBottom: theme.typography.pxToRem(15),
    paddingLeft: theme.typography.pxToRem(25),
    paddingRight: theme.typography.pxToRem(25),

    [theme.breakpoints.up(Breakpoints.Tablet)]: {
      width: theme.typography.pxToRem(355),
      paddingTop: theme.typography.pxToRem(60),
      paddingLeft: theme.typography.pxToRem(30),
      paddingRight: theme.typography.pxToRem(30),
    },
    [theme.breakpoints.up(Breakpoints.Laptop)]: {
      width: theme.typography.pxToRem(415),
      paddingTop: theme.typography.pxToRem(80),
      paddingLeft: theme.typography.pxToRem(40),
      paddingRight: theme.typography.pxToRem(40),
    },
    [theme.breakpoints.up(Breakpoints.Large)]: {
      width: theme.typography.pxToRem(550),
      paddingTop: theme.typography.pxToRem(100),
      paddingLeft: theme.typography.pxToRem(50),
      paddingRight: theme.typography.pxToRem(50),
    },
  },
}));

export function AuthSidebar(props: AuthFormProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AuthForm {...props} />
    </div>
  );
}
