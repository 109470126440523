import { isEmpty, isString, toString } from "lodash";

export const phoneRegExp = /^\+?7(\s?\(?\d{3}\)?){2}(\s?\d{2}){2}$/;
export const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function isValidEmail(str: string) {
  return emailRegExp.test(toString(str).toLowerCase());
}

export function isValidPhone(phone = ""): boolean {
  const number = phone.replace(/\D/g, "");

  return !!number.match(/^7[0-9]{10}$/);
}

export type EmptyObject = {
  readonly [key in string]?: undefined;
};

export function isObjectEmpty<T>(object: T | EmptyObject): object is EmptyObject {
  return isEmpty(object);
}

export function receivedData<T>(data: T, loading: boolean): data is NonNullable<T> {
  return !loading;
}

export function cast<T>(value: any) {
  return value as T;
}

export const getErrorTextFromError = (error: any): string =>
  (isString(error?.message) && error?.message) ||
  (isString(error?.error?.text) && error?.error?.text) ||
  (isString(error) && error);
