import { findLastIndex } from "lodash";
import React, { createRef, useEffect, useState } from "react";

import { TextInput } from "./TextInput";
import { makeStyles } from "../../theme/Theme";
import { Box, BoxProps } from "@mui/material";
import { Breakpoints } from "../../dto/ApplicationDTO";
import { Loader } from "./Loader";

const INPUTS = ["", "", "", "", "", ""];
const INPUT_REFS = INPUTS.map(() => createRef<HTMLInputElement>());

const useStyles = makeStyles((theme) => ({
  root: {},
  wrapperCodeField: { display: "flex" },
  loaderWrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    marginTop: 4,
  },
  input: {
    maxHeight: "56px",
    maxWidth: "48px",
    [theme.breakpoints.down(Breakpoints.Tablet)]: {
      maxWidth: "46px",
    },
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0",
    overflow: "hidden",
    "&.MuiTextField-root": {
      height: "56px",
      width: "48px",
      [theme.breakpoints.down(Breakpoints.Tablet)]: {
        width: "46px",
      },
      display: "table",
      "& > div.MuiInputBase-root": {
        height: "56px",
        width: "48px",

        [theme.breakpoints.down(Breakpoints.Tablet)]: {
          width: "46px",
        },
      },
      "&:not(:first-child)": {
        marginLeft: 2,
      },
      "&:not(:last-child)": {
        marginRight: 2,
      },

      "& > .MuiInputBase-root": {
        borderRadius: 12,

        "& > .MuiOutlinedInput-notchedOutline": {
          borderRadius: 12,
        },

        "& > .MuiInputBase-input": {
          textAlign: "center",

          maxWidth: "48px",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0",
          overflow: "hidden",
          flex: 1,
        },
      },
    },
  },
}));

export interface CodeInputProps extends Omit<BoxProps, "onChange"> {
  readonly autoFocus?: boolean;
  readonly onFocus?: () => void;
  readonly onChange?: (values: string) => void;
  readonly onComplete?: (values: string) => void;
  readonly disabled?: boolean;
  readonly isLoad?: boolean;
}

export function CodeInput({
  autoFocus,
  disabled,
  onChange,
  onComplete,
  onFocus,
  isLoad,
  ...props
}: CodeInputProps) {
  const classes = useStyles();

  const [code, setCode] = useState(INPUTS);

  const mouseUpHandler = (idx: number, arr: string[]) => {
    if (!arr[idx] && !arr[idx - 1]) {
      const activeIndex = findLastIndex(arr, (x) => !!x);

      if (activeIndex >= 0) {
        INPUT_REFS[activeIndex + 1]?.current?.focus();
      } else {
        INPUT_REFS[0]?.current?.focus();
      }
    }
  };

  const focusHandler = (idx: number) => {
    if (idx === 0 && onFocus) {
      onFocus();
    }
    setCode((x) => x.slice().fill("", idx));
    if (onChange) {
      onChange(code.slice().fill("", idx).join(""));
    }
  };

  const keyDownHandler = (idx: number, arr: string[], keyCode: string, key: string) => {
    const isBackspace = keyCode === "Backspace";

    if (isBackspace || !Number.isNaN(Number(key))) {
      if (isBackspace) {
        if (idx > 0) {
          INPUT_REFS[idx - 1]?.current?.focus();
          if (onChange) {
            onChange(
              code
                .slice()
                .fill("", idx - 1)
                .join(""),
            );
          }
        }
      } else {
        setCode((x) => {
          if (arr.filter(Boolean).length < INPUTS.length) {
            const newCode = x.slice().fill(key, idx, idx + 1);

            if (onChange) {
              onChange(newCode.join(""));
            }

            if (newCode.filter(Boolean).length === 6) {
              INPUT_REFS[idx]?.current?.blur();

              if (onComplete) {
                onComplete(newCode.join(""));
              }
            }

            return newCode;
          }

          return x;
        });

        if (arr.length >= idx + 1) {
          INPUT_REFS[idx + 1]?.current?.focus();
        }
      }
    }
  };

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        INPUT_REFS[0]?.current?.focus();
      }, 300);
    }
  }, [autoFocus]);

  return (
    <Box {...props} className={classes.root}>
      <Box className={classes.wrapperCodeField}>
        {code.map((value, idx, arr) => (
          <TextInput
            key={idx}
            value={value}
            autoCorrect="off"
            autoComplete="off"
            autoCapitalize="off"
            disabled={disabled}
            className={classes.input}
            inputRef={INPUT_REFS[idx]}
            onFocus={() => focusHandler(idx)}
            autoFocus={idx === 0 && autoFocus}
            onMouseUp={() => mouseUpHandler(idx, arr)}
            inputProps={{ maxLength: 1, inputMode: "numeric" }}
            onKeyDown={(event) => keyDownHandler(idx, arr, event.code, event.key)}
          />
        ))}
      </Box>
      {isLoad && (
        <div className={classes.loaderWrapper}>
          <Loader size={18} />
        </div>
      )}
    </Box>
  );
}
