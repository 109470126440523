import { PersistConfig } from "redux-persist";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ThemeMode } from "../dto/ApplicationDTO";
import { RootState } from "../store/configureStore";
import { getThemeMode } from "../helpers/ApplicationHelpers";

export const profileReducerPersistConfig: Partial<PersistConfig<Store.ProfileReducerState>> = {
  whitelist: ["themeMode", "activeThemeMode"],
};

const INITIAL_STATE_VALUES = {
  themeMode: { state: ThemeMode.System, mode: getThemeMode() },
};

const initialState: Store.ProfileReducerState = {
  themeMode: {},
  activeThemeMode: INITIAL_STATE_VALUES.themeMode,
};

export const profileSlice = createSlice({
  initialState,
  name: "profile",
  reducers: {
    changeThemeMode: (
      state,
      action: PayloadAction<Profile.ProfilePayloadProps<Partial<Application.Theme>>>,
    ) => {
      state.activeThemeMode = {
        ...state.activeThemeMode,
        mode: getThemeMode(action.payload.data.state),
      };

      if (action.payload.id != null) {
        const themeMode = {
          ...state.themeMode[action.payload.id],
          ...action.payload.data,
          mode: getThemeMode(action.payload.data.state),
        };

        state.activeThemeMode = themeMode;
        state.themeMode = {
          ...state.themeMode,
          [action.payload.id]: themeMode,
        };
      }
    },
  },
});

/**
 * Actions
 * */
export const { changeThemeMode } = profileSlice.actions;

/**
 * Selectors
 * */
export function themeModeSelector(id: number | undefined): (state: RootState) => Application.Theme {
  return ({ profile }: RootState) => {
    if (id != null) {
      return profile.themeMode[id] ?? INITIAL_STATE_VALUES.themeMode;
    }

    return profile.activeThemeMode;
  };
}

/**
 * Reducer
 * */
export default profileSlice.reducer;
