import NoInternetConnectionIcon_light from "./icons/NoInternetConnectionIcon_light.png";
import NoInternetConnectionIcon_dark from "./icons/NoInternetConnectionIcon_dark.png";
export const CommonComponent = () => {
  return (
    <>
      <link rel="preload" href={NoInternetConnectionIcon_light} as="image" />
      <link rel="preload" href={NoInternetConnectionIcon_dark} as="image" />
    </>
  );
};
