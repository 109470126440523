import { ComponentThemeOverride } from "@mui/material/styles";
import { Breakpoints } from "../../dto/ApplicationDTO";

export const MuiSnackbar: ComponentThemeOverride = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.wall.main,
      boxShadow: theme.customShadows.snackbar,
      color: theme.palette.total.main,
      padding: theme.typography.pxToRem(16),
      borderRadius: theme.typography.pxToRem(16),
      justifyContent: "start",

      [theme.breakpoints.up(Breakpoints.Tablet)]: {
        maxWidth: theme.typography.pxToRem(367),
      },
      [theme.breakpoints.down(Breakpoints.Tablet)]: {
        marginBottom: theme.typography.pxToRem(20 + 78 + 43),
        width: "calc(100% - 24px)",
        left: "12px",
      },
    }),
  },
};
