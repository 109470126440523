import React from "react";
import { Form, Formik } from "formik";
import Stack from "@mui/material/Stack";
import { object, string } from "yup";
import Typography from "@mui/material/Typography";

import { Loader } from "../ui/Loader";
import { CodeField } from "../form/CodeField";
import { makeStyles } from "../../theme/Theme";
import { PhoneField } from "../form/PhoneField";
import { ActionButton } from "../ui/ActionButton";
import { AuthFormButton } from "./AuthFormButton";
import { Breakpoints } from "../../dto/ApplicationDTO";
import { isValidPhone } from "../../utils/ValidateUtils";
import { AuthFormHeader, AuthFormHeaderProps } from "./AuthFormHeader";
import { ButtonBaseProps } from "@mui/material";
import { QRCodeAuth } from "../QRCodeAuth/QRCodeAuth";

const validationSchema = object({
  phone: string().test({
    test: (value) => isValidPhone(value),
    message: "Введите номер телефона",
  }),
});

const useStyles = makeStyles((theme) => ({
  loader: {
    marginTop: "15px !important",
    alignSelf: "center",
  },
  agreement: {
    textAlign: "left",
    whiteSpace: "pre-line",
    color: theme.palette.gary.main,
    marginTop: 30,
  },
  btnSendCode: {
    marginTop: "15px !important",
  },
  agreementActiveLink: {
    color: theme.palette.total.main,
    textAlign: "left",
    textDecoration: "underline",
    whiteSpace: "pre-line",
    cursor: "pointer",
  },
  title: {
    [theme.breakpoints.up(Breakpoints.Mobile)]: {
      marginBottom: "30px !important",
    },
    [theme.breakpoints.up(Breakpoints.Tablet)]: {
      marginBottom: "32px !important",
    },
    [theme.breakpoints.up(Breakpoints.Laptop)]: {
      marginBottom: "41px !important",
    },
  },
  phoneFieldWrapper: {
    marginBottom: "15px !important",
  },
  wrapperAgreement: {
    marginBottom: "30px !important",

    [theme.breakpoints.up(Breakpoints.Mobile)]: {
      marginTop: "30px !important",
    },
  },
  root: {
    ...theme.mixins.flexColumn,

    [theme.breakpoints.up(Breakpoints.Laptop)]: {
      maxWidth: theme.typography.pxToRem(255),
    },
    [theme.breakpoints.up(Breakpoints.Large)]: {
      maxWidth: theme.typography.pxToRem(310),
    },
  },
}));

const INITIAL_VALUES = { phone: "", code: "" };

export interface AuthFormProps extends AuthFormHeaderProps {
  readonly onAgreementClick?: ButtonBaseProps["onClick"];
  readonly onConsentProcessingPersonalData?: ButtonBaseProps["onClick"];
  readonly onPrivacyPolicy?: ButtonBaseProps["onClick"];
  readonly onSubmit: (values: Auth.AuthenticateBodyProps) => Promise<void>;
}

export function AuthForm({
  onSubmit,
  phone,
  onBackClick,
  onAgreementClick,
  onPrivacyPolicy,
  onConsentProcessingPersonalData,
}: AuthFormProps) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={validationSchema}
      onSubmit={(values, helpers) =>
        onSubmit(values).catch((error) => {
          const fieldName = phone ? "code" : "phone";

          helpers.setFieldError(fieldName, error.message);
        })
      }
    >
      {({ values, isSubmitting, handleSubmit, setFieldValue, setTouched, setFieldTouched }) => {
        const submitHandler = () => {
          setTouched({
            phone: true,
          });

          return handleSubmit();
        };
        return (
          <Form className={classes.root} onSubmit={submitHandler}>
            <Stack>
              <Stack className={classes.title}>
                <AuthFormHeader
                  phone={phone}
                  onBackClick={() => {
                    setFieldValue("code", "");
                    onBackClick();
                  }}
                />
              </Stack>

              <Stack>
                {!phone && (
                  <>
                    <Stack>
                      <Stack className={classes.phoneFieldWrapper}>
                        <PhoneField
                          name="phone"
                          inputProps={{
                            placeholder: "Телефон",
                            id: "AuthFormPhoneField",
                          }}
                        />
                      </Stack>
                      <ActionButton loading={isSubmitting} onClick={submitHandler}>
                        Войти
                      </ActionButton>
                    </Stack>
                    <Stack className={classes.wrapperAgreement}>
                      <Typography variant={"button-filled"} className={classes.agreement}>
                        Продолжая, вы соглашаетесь с условиями{" "}
                        <span onClick={onPrivacyPolicy} className={classes.agreementActiveLink}>
                          Политики конфиденциальности
                        </span>{" "}
                        и даёте{" "}
                        <span
                          onClick={onConsentProcessingPersonalData}
                          className={classes.agreementActiveLink}
                        >
                          согласие
                        </span>{" "}
                        на обработку персональных данных.
                      </Typography>
                    </Stack>
                    <QRCodeAuth />
                  </>
                )}

                {!!phone && (
                  <Stack>
                    <CodeField
                      name="code"
                      inputProps={{
                        autoFocus: true,
                        onComplete: () => handleSubmit(),
                      }}
                    />
                    {!isSubmitting && (
                      <AuthFormButton
                        type="submit"
                        className={classes.btnSendCode}
                        phone={phone}
                        loading={isSubmitting}
                      />
                    )}
                    {isSubmitting && <Loader className={classes.loader} />}
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
}
