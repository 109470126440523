export const DEFAULT_CURRENCY_SIGN = "₽";

export const DEFAULT_CURRENCY: Currency.Props = {
  id: 0,
  rate: 1,
  code: "RUB",
  title: "Российский рубль",
  icon: DEFAULT_CURRENCY_SIGN,
};

/*
₽ - рубль
$ - доллар сша
€ - евро
¥ - йена
₣ - швейцарский франк
$ - гонконгский доллар
¥ - китайский юань
*/
