import { get, identity, isArray, isEqual, isEqualWith, pickBy } from "lodash";

export function isEqualData<T>(a: T, b: T): boolean {
  if (isArray(a)) {
    return isEqualWith(a, b, isEqual);
  }

  return isEqual(a, b);
}

export function isEqualChild<
  T,
  K1 extends keyof T,
  K2 extends keyof T[K1],
  K3 extends keyof T[K1][K2],
  K4 extends keyof T[K1][K2][K3],
  K5 extends keyof T[K1][K2][K3][K4],
  K6 extends keyof T[K1][K2][K3][K4][K5],
>(a: T, b: T, key1: K1, key2?: K2, key3?: K3, key4?: K4, key5?: K5, key6?: K6) {
  const path = [key1, key2, key3, key4, key5, key6].filter((x) => x != null) as string[];
  const aChild = get(a, path);
  const bChild = get(b, path);

  return isEqual(aChild, bChild);
}

export function tryStringifyJSON<T>(data: T): null | string {
  try {
    return JSON.stringify(data);
  } catch {
    return null;
  }
}

export function identityObject<T = any>(object: T): T {
  return pickBy(object as any, identity) as any;
}
