import { PersistConfig } from "redux-persist";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../store/configureStore";

export const authReducerPersistConfig: Partial<PersistConfig<Store.AuthReducerState>> = {
  whitelist: ["code", "sessionId"],
};

const initialState: Store.AuthReducerState = {};

export const authSlice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    changeCode: (state, action: PayloadAction<string>) => {
      state.code = action.payload;
    },
    changeSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload;
    },
    logout: (state) => {
      state.code = undefined;
      state.sessionId = undefined;
    },
  },
});

/**
 * Actions
 * */
export const { changeCode, changeSessionId, logout } = authSlice.actions;

/**
 * Selectors
 * */
export function sessionIdSelector({ auth }: RootState): string | undefined {
  return auth.sessionId;
}

export function isAuthorizedSelector({ auth }: RootState): boolean {
  return !!auth.code && !!auth.sessionId;
}

/**
 * Reducer
 * */
export default authSlice.reducer;
