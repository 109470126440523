import React, { useEffect, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { makeStyles } from "../../theme/Theme";
import Typography from "@mui/material/Typography";
import { Image } from "../ui/Image";
import { useCheckQrCodeAuthQuery, useLazyGetQrCodeAuthQuery } from "../../api/qrCodeApi";
import { PlaceholderBox } from "../placeholder/PlaceholderBox";
import classNames from "classnames";
import { formatHexToRGBA } from "../../utils";
import { RefreshTwoArrowIcon } from "../icons/RefreshTwoArrowIcon";
import { changeCode, changeSessionId } from "../../slices/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../constants/AppRoutes";
import { useSnackbar } from "../app/NotificationProvider";
import { Breakpoints } from "../../dto/ApplicationDTO";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    width: 255,
    background: theme.palette.walter.main,
    borderRadius: 20,
    [theme.breakpoints.only(Breakpoints.Tablet)]: {
      width: 215,
    },
    [theme.breakpoints.down(Breakpoints.Tablet)]: {
      flex: 1,
      width: "100%",
      display: "flex",
      flexDirection: "row !important",
      gap: 20,
    },
    [theme.breakpoints.up(Breakpoints.Large)]: {
      flex: 1,
      width: "100%",
      display: "flex",
      flexDirection: "row !important",
      gap: 20,
    },
  },
  qrCodeSize: {
    width: 140,
    height: 140,
    [theme.breakpoints.down(Breakpoints.Tablet)]: {
      width: 100,
      height: 100,
    },
  },
  qrCodeBgForceRefresh: {
    borderRadius: 20,
    backgroundColor: formatHexToRGBA({ color: theme.palette.gary.main, alpha: 0.2 }),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  qrCodeWrapper: {
    marginBottom: 20,
    [theme.breakpoints.up(Breakpoints.Large)]: {
      marginBottom: 0,
    },
    [theme.breakpoints.down(Breakpoints.Tablet)]: {
      marginBottom: 0,
    },
  },
  bottomTextWrapper: {},
  topText: {
    marginBottom: "8px !important",
  },
  bottomText: {},
  wrapperIconRefresh: {
    backgroundColor: theme.palette.bloom.main,
    width: 50,
    height: 50,
    borderRadius: 20,
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
  },
}));
export const QRCodeAuth = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notification = useSnackbar();

  const [timeReRequestQrCode, setTimeReRequestQrCode] = useState<number>(0);
  const [qrData, setQrData] = useState<string | undefined>(undefined);
  const [errorManyRequests, setErrorManyRequests] = useState(false);

  const [
    getQrCodeAuth,
    { data: qrCodeData, isLoading: isLoadingQrCode, isFetching: isFetchingQrCode },
  ] = useLazyGetQrCodeAuthQuery();
  const { data: dataCheckQrCode } = useCheckQrCodeAuthQuery(
    {
      qrCodeContent: qrCodeData?.data?.qrData || "",
    },
    { pollingInterval: 3000, skip: errorManyRequests },
  );

  const forceRefreshQRCode = () => {
    getQrCodeAuth({ prevQrData: qrData }).unwrap().catch(showErrorManyRequest);
  };

  const enterInSystemBySessionId = () => {
    if (dataCheckQrCode?.data?.sessionId && qrData) {
      dispatch(changeCode(qrData?.repeat(2).slice(0, 96)));
      dispatch(changeSessionId(dataCheckQrCode?.data?.sessionId || ""));
      navigate(AppRoutes.DashboardBriefcaseLink);
    }
  };

  const showErrorManyRequest = (error: any) => {
    notification.openSnackbarError(error);
    setErrorManyRequests(error?.code === 17 || error?.code === 1 || error.code === 999);
  };

  const textBottomDescrQrCode = useMemo(
    () =>
      errorManyRequests
        ? "Пожалуйста обновите QR-код для входа."
        : isLoadingQrCode
        ? "Загружаем QR-код, пожалуйста подождите."
        : "Отсканируйте QR-код через приложение ФБ Август.",
    [errorManyRequests, isLoadingQrCode],
  );

  useEffect(() => {
    enterInSystemBySessionId();
  }, [dataCheckQrCode?.data?.sessionId, qrData]);

  useEffect(() => {
    if (qrCodeData?.data?.expiredTime) setTimeReRequestQrCode(+qrCodeData.data.expiredTime);
    if (qrCodeData?.data?.qrData) setQrData(qrCodeData.data.qrData);
  }, [qrCodeData?.data?.expiredTime, qrCodeData?.data?.qrData]);

  useEffect(() => {
    if (errorManyRequests) return;
    let interval: any = undefined;
    if (timeReRequestQrCode) {
      interval = setInterval(() => {
        getQrCodeAuth({ prevQrData: qrData }).unwrap().catch(showErrorManyRequest);
      }, timeReRequestQrCode * 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [timeReRequestQrCode]);

  useEffect(() => {
    setErrorManyRequests(qrCodeData?.code === 17);
  }, [qrCodeData]);
  useEffect(() => {
    getQrCodeAuth({}).unwrap().catch(showErrorManyRequest);
  }, []);

  // обработка ошибок
  useEffect(() => {
    if (qrCodeData?.code !== 0 && qrCodeData?.text) {
      notification.openSnackbarError(qrCodeData?.text);
    }
    if (dataCheckQrCode?.code !== 0 && dataCheckQrCode?.text) {
      notification.openSnackbarError(dataCheckQrCode?.text);
    }
  }, [dataCheckQrCode?.code, dataCheckQrCode?.text, qrCodeData?.code, qrCodeData?.text]);
  return (
    <Stack className={styles.root}>
      <Stack className={styles.qrCodeWrapper}>
        {isFetchingQrCode ? (
          <PlaceholderBox className={styles.qrCodeSize} />
        ) : errorManyRequests ? (
          <div className={classNames(styles.qrCodeBgForceRefresh, styles.qrCodeSize)}>
            <div onClick={forceRefreshQRCode} className={styles.wrapperIconRefresh}>
              <RefreshTwoArrowIcon />
            </div>
          </div>
        ) : isLoadingQrCode && !qrCodeData?.data?.qrCode ? (
          <PlaceholderBox className={styles.qrCodeSize} />
        ) : (
          <Image className={styles.qrCodeSize} src={qrCodeData?.data?.qrCode || ""} />
        )}
      </Stack>
      <Stack className={styles.bottomTextWrapper}>
        <Typography variant={"input2"} className={styles.topText}>
          Быстрый вход
        </Typography>
        <Typography variant={"text2"} className={styles.bottomText}>
          {textBottomDescrQrCode}
        </Typography>
      </Stack>
    </Stack>
  );
};
