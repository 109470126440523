import { ResponseCode } from "../dto/ApplicationDTO";

export interface AppErrorProps<T = Record<string, unknown>> {
  readonly data?: T;
  readonly text?: string;
  readonly status?: number;
  readonly code?: Application.ResponseCodeType;
}

export class AppError<T = Record<string, unknown>> extends Error {
  public readonly data?: T;
  public readonly text: string;
  public readonly status: number;
  public readonly code: Application.ResponseCodeType;

  constructor({
    data,
    code = ResponseCode.Unknown,
    text = "Неизвестная ошибка",
    status = 500,
  }: AppErrorProps<T>) {
    super(text);

    this.text = text;
    this.code = code;
    this.data = data;
    this.status = status;
  }
}
