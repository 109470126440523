import React from "react";
import { Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";

import { useTheme } from "../../theme/Theme";
import { Image } from "../../components/ui/Image";
import { BackButton } from "../../components/buttons/BackButton";
import { AppLayoutContent } from "../../components/app-layout/AppLayoutContent";
import { AppLayoutHeaderContent } from "../../components/app-layout/AppLayoutHeaderContent";

export interface AgreementPageParams {
  title: string;
  text: string;
}

export function AgreementPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const dataAgreement = location.state as AgreementPageParams;
  return (
    <>
      <AppLayoutHeaderContent />

      <Box sx={{ position: "relative" }}>
        <Image
          src="/images/bg.png"
          sx={{ width: "100%", minHeight: (theme) => theme.typography.pxToRem(68) }}
        />

        <AppLayoutContent
          sx={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: "absolute",
            py: (theme) => theme.typography.pxToRem(20),
          }}
        >
          <BackButton color={theme.palette.const.main} onClick={() => navigate(-1)} />
        </AppLayoutContent>
      </Box>

      <AppLayoutContent>
        <Typography variant="h1" sx={{ mt: (theme) => theme.typography.pxToRem(30) }}>
          {dataAgreement.title}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: (theme) => theme.typography.pxToRem(26),
          }}
        >
          <Typography style={{ whiteSpace: "pre-line" }} variant="input1">
            {dataAgreement.text}
          </Typography>
        </Box>
      </AppLayoutContent>
    </>
  );
}
