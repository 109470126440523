import { toFinite } from "lodash";

import { formatCurrencyNumber } from "../utils";
import { getFractionalPart } from "../utils/NumberUtils";
import { DEFAULT_CURRENCY_SIGN } from "../constants/CurrencyConstants";

export function convertPriceWithCurrency(price: number, currency: Currency.Props) {
  const fraction = getFractionalPart(price);

  const value = price / currency.rate;

  return toFinite(value.toFixed(fraction.length));
}

export function calculatePrice(price: number, currency?: Currency.Props, showSign = true) {
  const sign = showSign ? currency?.icon || DEFAULT_CURRENCY_SIGN : undefined;

  if (currency) {
    const value = convertPriceWithCurrency(price, currency);

    return [formatCurrencyNumber(value), sign].filter(Boolean).join(" ");
  }

  return [formatCurrencyNumber(price), sign].filter(Boolean).join(" ");
}
