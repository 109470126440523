import qs from "qs";
import { isString } from "lodash";
import { pathParams } from "path-params";

export interface CreateUrlOptionsProps<Q, P> {
  readonly query?: Q;
  readonly params?: P;
  readonly host?: string;
}

export function createUrl<Q, P>(path: string, options: CreateUrlOptionsProps<Q, P>): string {
  const query = isString(options.query)
    ? options.query
    : qs.stringify(options.query, { arrayFormat: "brackets" });
  const pathname = [options.host, path].filter(Boolean).join("/");
  const url = [pathname, query].filter(Boolean).join("?");

  if (options?.params) {
    return pathParams<P>(url, options.params);
  }

  return url;
}

export function mergeQuery(url: string, query: Record<string, unknown>): string {
  const [path, urlQuery] = url.split("?");

  const parsedQuery = qs.parse(urlQuery);

  return createUrl(path, {
    query: {
      ...parsedQuery,
      ...query,
    },
  });
}

export function openInNewTab(url: string) {
  /** @ts-ignore */
  window.open(url, "_blank").focus();
}

export function downloadFile(url: string, fileName?: string) {
  const link = document.createElement("a");
  link.style.display = "none";
  link.setAttribute("download", fileName || "Платежное поручение");
  link.href = url;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
