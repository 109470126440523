import React from "react";
import { Outlet } from "react-router-dom";

import { makeStyles } from "../../theme/Theme";

const useStyles = makeStyles(() => ({
  root: { display: "flex", flexDirection: "column", flex: 1 },
}));

export function StartLayout() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Outlet />
    </div>
  );
}
