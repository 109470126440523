import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchQuery } from "../helpers/ApiHelpers";
import { ApiRoutes } from "../constants/ApiRoutes";
import { createUrl } from "../utils";

enum RequisiteApiTags {
  RequisitesList = "requisites-list",
}
type DataForConfirmTypeBySms = Pick<Application.RequestForConfirmOperation, "code"> & {
  actionId: string;
};

type DataForConfirmTypeBy2FA = Required<Application.RequestForConfirmOperation> & {
  actionId: string;
};

export const requisiteApi = createApi({
  baseQuery: fetchQuery(),
  reducerPath: "requisiteApi",
  tagTypes: [RequisiteApiTags.RequisitesList],
  endpoints: (builder) => ({
    requisiteList: builder.query<Application.ResponseProps<Requisite.ShortProps[]>, void>({
      query: () => ApiRoutes.RequisiteList,
      providesTags: [RequisiteApiTags.RequisitesList],
    }),
    getInfoBanks: builder.query<Application.ResponseProps<Bank.BankInfo[]>, { bankBik?: string }>({
      query: (data) => createUrl(ApiRoutes.BankList, { query: data }),
    }),
    changeRequisite: builder.mutation<
      Application.ResponseProps<Requisite.ChangeRequisiteResponse>,
      Requisite.ChangeRequisiteRequest
    >({
      query: (data) => ({
        method: "POST",
        body: data,
        url: ApiRoutes.ChangeRequisite,
      }),
    }),

    ConfirmChangeRequisiteBySms: builder.mutation<
      Application.ResponseWithOrderId,
      DataForConfirmTypeBySms
    >({
      query: (data) => createUrl(ApiRoutes.ConfirmChangeRequisiteBySms, { query: data }),
    }),
    ConfirmChangeRequisiteBy2FA: builder.mutation<
      Application.ResponseWithOrderId,
      DataForConfirmTypeBy2FA
    >({
      query: (data) => createUrl(ApiRoutes.ConfirmChangeRequisiteBy2Fa, { query: data }),
    }),
  }),
});

export const {
  useRequisiteListQuery,
  useGetInfoBanksQuery,
  useLazyGetInfoBanksQuery,
  useChangeRequisiteMutation,
  useConfirmChangeRequisiteBySmsMutation,
  useConfirmChangeRequisiteBy2FAMutation,
} = requisiteApi;
