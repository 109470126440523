import React from "react";
import { useField } from "formik";

import { FormControl, FormControlProps } from "./FormControl";
import { PhoneInput, PhoneInputProps } from "../ui/PhoneInput";

interface Props extends Omit<FormControlProps, "children" | "error"> {
  readonly name: string;
  readonly inputProps?: Omit<PhoneInputProps, "value">;
}

export function PhoneField({ name, inputProps, ...props }: Props) {
  const [field, meta, helpers] = useField(name);

  const error = meta.touched ? meta.error : undefined;

  return (
    <FormControl {...props} error={error}>
      <PhoneInput
        {...inputProps}
        value={field.value}
        onChangeText={(value) => helpers.setValue(value)}
        inputProps={{
          inputMode: "tel",
          ...inputProps?.inputProps,
        }}
        onBlur={(event) => {
          if (inputProps?.onBlur) {
            inputProps.onBlur(event);
          }

          helpers.setTouched(true);
        }}
      />
    </FormControl>
  );
}
