import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { makeStyles } from "../../theme/Theme";
import { AppRoutes } from "../../constants/AppRoutes";
import { Breakpoints } from "../../dto/ApplicationDTO";
import { AuthPromo } from "../../components/auth/AuthPromo";
import { AuthSidebar } from "../../components/auth/AuthSidebar";
import { formatAuthenticateBody } from "../../helpers/AuthHelpers";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import { useCheckCodeMutation, useSendCodeMutation } from "../../api/authApi";
import { changeCode, changeSessionId, isAuthorizedSelector } from "../../slices/authSlice";
import { useAgreementQuery } from "../../api/commonApi";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.flexFull,
    ...theme.mixins.flexColumn,
  },
  content: {
    ...theme.mixins.flexFull,
    [theme.breakpoints.down(Breakpoints.Tablet)]: { flexDirection: "column-reverse" },
  },
}));

export function AuthPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const agreementQuery = useAgreementQuery();

  const textAgreement = agreementQuery.data?.data?.text ?? "";

  const isAuthorized = useShallowEqualSelector(isAuthorizedSelector);

  const [sendCodeMutation] = useSendCodeMutation();
  const [checkCodeMutation] = useCheckCodeMutation();

  const [authPhone, setAuthPhone] = useState<string | undefined>();

  if (isAuthorized) {
    return <Navigate to="/" />;
  }

  const submitHandler = (values: Auth.AuthenticateBodyProps) => {
    if (values.code) {
      return checkCodeMutation(formatAuthenticateBody(values))
        .unwrap()
        .then(() => {
          if (values.code) {
            dispatch(changeCode(values.code));
            setAuthPhone(values.phone);

            navigate(AppRoutes.DashboardBriefcaseLink);
          }
        });
    }

    return sendCodeMutation(formatAuthenticateBody(values))
      .unwrap()
      .then((x) => {
        if (x?.sessionId) {
          dispatch(changeSessionId(x.sessionId));
          setAuthPhone(values.phone);
        }
      });
  };
  const toAgreementClick = () => {
    navigate(AppRoutes.StartAgreement, {
      state: { text: textAgreement, title: "Пользовательское соглашение" },
    });
  };
  const toPrivacyPolicy = () => {
    window.open("https://www.fbaugust.ru/upload/iblock/33b/4qe11520fodb5aix95yhqs6pibpv4myr.txt");

    // navigate(AppRoutes.StartAgreement, {
    //   state: { text: textAgreement, title: "Политика конфиденциальности" },
    // });
  };
  const toConsentProcessingPersonalData = async () => {
    window.open("https://be.fbaugust.ru:2841/api/agreement_web");
    // navigate(AppRoutes.StartAgreement, {
    //   state: { text: textAgreement, title: "Согласие на обработку персональных данных" },
    // });
  };
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <AuthPromo />

        <AuthSidebar
          phone={authPhone}
          onSubmit={submitHandler}
          onBackClick={() => setAuthPhone(undefined)}
          onAgreementClick={toAgreementClick}
          onConsentProcessingPersonalData={toConsentProcessingPersonalData}
          onPrivacyPolicy={toPrivacyPolicy}
        />
      </div>
    </div>
  );
}
