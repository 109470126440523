import { useEffect } from "react";

import { useLazyProfileQuery } from "../api/profileApi";
import { useLazySettingsQuery } from "../api/settingsApi";
import { isAuthorizedSelector } from "../slices/authSlice";
import { useLazyStrategyListQuery } from "../api/briefcaseApi";
import { useLazyGetReserveListQuery } from "../api/manageFundsApi";
import { useAgreementQuery, useAugustInfoQuery } from "../api/commonApi";
import { useShallowEqualSelector } from "../hooks/useShallowEqualSelector";

export function PrefetchingPrivateContainer() {
  const isAuthorized = useShallowEqualSelector(isAuthorizedSelector);

  const [loadProfile] = useLazyProfileQuery();
  const [loadSettings] = useLazySettingsQuery();

  const [loadStrategyList] = useLazyStrategyListQuery();
  const [loadReserveList] = useLazyGetReserveListQuery();

  // Public
  useAgreementQuery();
  useAugustInfoQuery();

  // Private
  useEffect(() => {
    if (isAuthorized) {
      loadProfile();
      loadSettings();

      loadStrategyList({
        woReserve: true,
      });
      loadReserveList();
    }
  }, [isAuthorized]);

  return null;
}
