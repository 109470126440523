import { Box, Stack, Typography } from "@mui/material";

import { useTheme } from "../../theme/Theme";
import { Breakpoints } from "../../dto/ApplicationDTO";
import { useBreakpointValue } from "../../hooks/useBreakpointValue";
import { LogoWithTextHorizontallcon } from "../icons/LogoWithTextHorizontallcon";
import { AUTH_PROMO_URL } from "../../constants/AppConstants";

export function AuthPromo() {
  const theme = useTheme();

  const size = useBreakpointValue({ upSm: 127, upLg: 200 }, 70);
  const title = useBreakpointValue(
    { upMd: "Стандартная стратегия\n“Инвестинг”" },
    "Стандартная\nстратегия\n“Инвестинг”",
  );
  const text = useBreakpointValue(
    {
      upSm: "Живи здесь и сейчас,\nа будущее доверь\nпрофессионалам.",
      upMd: "Живи здесь и сейчас, а будущее доверь\nпрофессионалам.",
    },
    "Живи здесь и сейчас,\nа будущее доверь профессионалам.",
  );

  return (
    <Box
      sx={{
        flex: 1,
        overflow: "hidden",
        userSelect: "none",
        position: "relative",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundImage: "linear-gradient(107.78deg, #4062FF 0%, #2841B0 98.99%)",
      }}
    >
      <Box
        sx={{
          width: "385px",
          height: "432px",
          right: "-205px",
          bottom: "-75px",
          position: "absolute",
          backgroundSize: "100%",
          backgroundRepeat: "no-repeat",
          backgroundImage: "url('/images/chart.png')",

          [theme.breakpoints.up(Breakpoints.Tablet)]: {
            width: "666px",
            height: "747px",
            right: "-263px",
            bottom: "-199px",
          },
          [theme.breakpoints.up(Breakpoints.Laptop)]: {
            width: "711px",
            height: "798px",
            right: "-167px",
            bottom: "-228px",
          },
          [theme.breakpoints.up(Breakpoints.Large)]: {
            width: "1012px",
            height: "1135px",
            right: "-203px",
            bottom: "-540px",
          },
        }}
      />

      <Box
        sx={{
          gap: "25px",
          top: "25px",
          left: "25px",
          right: "25px",
          bottom: "25px",
          display: "flex",
          position: "absolute",
          flexDirection: "column",

          [theme.breakpoints.up(Breakpoints.Tablet)]: {
            gap: "60px",
            top: "40px",
            left: "60px",
            right: "unset",
            bottom: "unset",
          },
          [theme.breakpoints.up(Breakpoints.Large)]: {
            top: "60px",
            gap: "100px",
            left: "100px",
            right: "unset",
            bottom: "unset",
          },
        }}
      >
        <LogoWithTextHorizontallcon color="#fbfdff" size={size} />

        <Stack>
          <Typography
            variant="auth1"
            sx={{
              mb: "5px",
              color: "#fbfdff",
              whiteSpace: "pre-wrap",

              [theme.breakpoints.up(Breakpoints.Tablet)]: {
                mb: "15px",
              },
              [theme.breakpoints.up(Breakpoints.Laptop)]: {
                mb: "6px",
              },
              [theme.breakpoints.up(Breakpoints.Large)]: {
                mb: "10px",
              },
            }}
          >
            {title}
          </Typography>

          <Typography
            variant="auth2"
            sx={{
              mb: "30px",
              color: "#FAFF00",
              whiteSpace: "pre-wrap",

              [theme.breakpoints.up(Breakpoints.Large)]: {
                mb: "40px",
              },
            }}
          >
            {text}
          </Typography>

          <Box
            component="a"
            target="_blank"
            href={AUTH_PROMO_URL}
            sx={{
              textDecoration: "none",
              width: "100%",
              height: "40px",
              display: "flex",
              alignItems: "center",
              borderRadius: "17px",
              justifyContent: "center",
              backgroundColor: "#fbfdff",
              cursor: "pointer",

              [theme.breakpoints.up(Breakpoints.Tablet)]: {
                width: "265px",
              },
              [theme.breakpoints.up(Breakpoints.Large)]: {
                width: "420px",
                height: "64px",
                borderRadius: "27px",
              },
            }}
          >
            <Typography variant="auth3" sx={{ color: theme.palette.bloom.main }}>
              Узнать больше
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
