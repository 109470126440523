import React from "react";

import { useColor } from "../../hooks/useColor";

export function LogoWithTextHorizontallcon({ color, size = 200, ...props }: Application.IconProps) {
  const iconColor = useColor({ light: (palette) => color || palette.arctic.main });

  const svgProps = {
    width: size,
    height: size / 4,
  };

  return (
    <svg
      viewBox="0 0 202 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      {...svgProps}
    >
      <path
        d="M13.3134 0.592697C13.0353 0.65892 12.7836 0.844349 12.5982 1.12249C12.4459 1.33441 12.4459 1.37414 12.4459 3.31451V5.29461H13.6379H14.83V4.13568V2.97676H32.0482H49.2665V20.195V37.4133H32.0482H14.83V24.8969V12.3806H13.6379H12.4459V25.791C12.4459 40.5391 12.4128 39.4795 12.8432 39.698C13.0154 39.7841 15.181 39.7974 32.0482 39.7974C48.9155 39.7974 51.081 39.7841 51.2532 39.698C51.6837 39.4729 51.6506 41.102 51.6506 20.195C51.6506 -0.586094 51.6837 0.963551 51.2731 0.68541C51.0943 0.559586 50.6373 0.559586 32.3131 0.546341C21.9888 0.546341 13.4393 0.566208 13.3134 0.592697Z"
        fill={iconColor}
      />
      <path
        d="M30.3253 12.4095C30.3253 12.4227 29.7756 15.8796 29.1001 20.0915C28.4247 24.3034 27.875 27.7934 27.875 27.8463C27.875 27.9324 28.0472 27.9457 29.0207 27.9457H30.1664L30.3584 26.555L30.5505 25.1643H32.0273H33.4974L33.5372 25.3762C33.6034 25.7802 33.9014 27.8066 33.9014 27.8728C33.9014 27.9192 34.2855 27.9457 35.0603 27.9457C35.9411 27.9457 36.2192 27.9258 36.2192 27.8596C36.2192 27.8199 35.6563 24.3232 34.9742 20.0981L33.7292 12.4161L32.0273 12.3963C31.0935 12.3896 30.3253 12.3896 30.3253 12.4095ZM32.5769 19.0783C32.8683 21.0253 33.1266 22.6941 33.1464 22.7934L33.1862 22.9789H32.0537C31.4312 22.9789 30.9213 22.959 30.9213 22.9259C30.9147 22.8332 32.014 15.5022 32.0273 15.522C32.0405 15.5287 32.2855 17.1313 32.5769 19.0783Z"
        fill={iconColor}
      />
      <path
        d="M0.417756 14.8985C-0.0325678 15.17 0.000544248 13.6799 0.000544248 31.9644C0.000544248 48.2555 0.000544242 48.5072 0.132992 48.7191C0.199217 48.8383 0.358154 48.984 0.477358 49.0502C0.689275 49.1628 1.43761 49.1694 17.2321 49.1694H33.7682L33.947 49.0304C34.0464 48.9575 34.192 48.8118 34.2649 48.7125C34.3973 48.5337 34.404 48.4476 34.4238 45.3218L34.4437 42.1166H33.2119H31.9868V44.4344V46.7523H17.1857H2.38461V31.9512V17.1501H6.25872H10.1328V15.9581V14.766H5.38456C0.788611 14.766 0.623051 14.7726 0.417756 14.8985Z"
        fill={iconColor}
      />
      <path
        d="M168.554 0.0600967C165.545 0.315578 163.459 1.86266 162.536 4.54521C161.94 6.24842 161.869 7.65357 161.926 18.0006C161.983 26.6301 162.011 27.7372 162.224 28.5037C163.317 32.2649 165.673 33.9539 169.86 33.9681C172.387 33.9681 174.246 33.2727 175.594 31.8107C177.156 30.1217 177.51 28.8301 177.61 24.345L177.695 21.0521H175.112H172.543V23.8624C172.543 27.2121 172.401 27.9217 171.592 28.5321C171.166 28.8585 170.911 28.9295 170.073 28.9721C168.838 29.0146 168.114 28.674 167.66 27.794C167.362 27.2405 167.362 27.0418 167.32 17.5748C167.291 12.2664 167.32 7.59679 167.362 7.18518C167.518 5.85101 168.356 5.01359 169.605 4.90005C170.612 4.81489 171.294 5.05618 171.819 5.6523C172.429 6.34777 172.543 6.9439 172.543 9.44194V11.6845H175.112H177.695L177.61 9.01613C177.51 5.14133 177.014 3.53748 175.438 1.94782C173.92 0.429127 171.592 -0.209579 168.554 0.0600967Z"
        fill={iconColor}
      />
      <path
        d="M68.2053 0.359772C68.2053 0.388157 66.9989 7.85389 65.537 16.9661L62.8828 33.5439H65.3241C67.5382 33.5439 67.7795 33.5156 67.8505 33.2885C67.8789 33.1607 68.0918 31.8124 68.3047 30.3078L68.7021 27.5827H71.8389H74.9614L75.373 30.3788C75.6001 31.9259 75.813 33.2743 75.8414 33.3594C75.8982 33.5014 76.5794 33.5439 78.6091 33.5439C81.093 33.5439 81.2917 33.5298 81.2349 33.2885C81.1923 33.1607 79.9859 25.6808 78.5523 16.6964L75.9549 0.331383H72.0943C69.9653 0.331383 68.2195 0.345577 68.2053 0.359772ZM74.1382 22.8421C74.1098 22.8847 73.0311 22.8847 71.7537 22.8705L69.4118 22.8279L70.5047 15.5893C71.1008 11.6009 71.6401 8.02421 71.6969 7.64099C71.7963 7.03067 71.9666 7.98163 73.0027 14.8654C73.6556 19.2228 74.1666 22.8137 74.1382 22.8421Z"
        fill={iconColor}
      />
      <path
        d="M89.2148 16.9377V33.5439H94.2961C99.9167 33.5439 100.499 33.473 102.031 32.6498C103.635 31.7982 104.657 30.4356 105.225 28.4059C105.58 27.0859 105.566 21.3944 105.197 20.0602C104.671 18.1583 103.65 16.7532 102.358 16.1428L101.861 15.9015L102.599 15.3622C103.479 14.7377 104.118 13.8009 104.572 12.5377C104.856 11.7003 104.884 11.3597 104.899 8.70549C104.899 5.54036 104.785 4.84488 104.004 3.41135C103.493 2.46039 102.301 1.41008 101.308 1.04105C99.7464 0.444931 98.6393 0.345577 93.7993 0.331383H89.2148V16.9377ZM97.4896 5.28488C98.3554 5.42682 98.8522 5.79584 99.2638 6.59067C99.576 7.1868 99.5902 7.34292 99.5476 9.62806C99.5051 12.3816 99.4057 12.6938 98.4122 13.3609C97.8728 13.7158 97.6883 13.7441 96.2122 13.8009L94.6083 13.8435V9.50032V5.15714L95.7154 5.17133C96.3115 5.17133 97.1206 5.22811 97.4896 5.28488ZM98.7102 19.0951C99.1928 19.3505 99.4483 19.606 99.718 20.1312C100.059 20.8125 100.073 20.8976 100.073 23.8924C100.073 27.4834 99.9734 27.8382 98.8664 28.3917C98.3128 28.6756 98.0289 28.7182 96.4251 28.7182H94.6083V23.6511V18.5983L96.3399 18.6835C97.7593 18.7544 98.1851 18.8254 98.7102 19.0951Z"
        fill={iconColor}
      />
      <path
        d="M114.348 16.9377V33.5439H116.973H119.599V19.3505V5.15714H123.857H128.115V2.74426V0.331383H121.231H114.348V16.9377Z"
        fill={iconColor}
      />
      <path
        d="M135.827 0.641842C135.87 0.826355 137.502 6.61726 139.447 13.5153L142.981 26.0622L142.782 26.7577C142.342 28.2054 141.278 28.8583 139.362 28.8583H138.325V31.2712V33.6841H139.929C142.853 33.6841 144.883 32.9602 146.288 31.4415C147.239 30.4196 147.679 29.5964 148.247 27.8648C148.644 26.6583 154.506 1.05345 154.506 0.542488C154.506 0.357971 154.08 0.329586 151.752 0.329586H148.999L148.829 1.06764C147.65 6.5321 146.572 12.0391 146.075 15.0907C145.734 17.163 145.422 18.7526 145.38 18.6391C145.337 18.5255 144.94 16.6946 144.514 14.594C144.074 12.4791 143.123 8.43402 142.399 5.58114L141.093 0.400555L138.411 0.357971L135.742 0.329586L135.827 0.641842Z"
        fill={iconColor}
      />
      <path
        d="M184.889 2.74426V5.15714H187.585H190.282V19.3505V33.5439H192.979H195.676V19.3505V5.15714H198.372H201.069V2.74426V0.331383H192.979H184.889V2.74426Z"
        fill={iconColor}
      />
      <path
        d="M62.8242 38.9665C62.8242 39.0516 63.3068 40.84 63.9029 42.9406C64.5416 45.2116 64.939 46.858 64.8823 47.0283C64.8255 47.227 64.57 47.3832 64.1584 47.4825L63.5339 47.6528V48.4051V49.1573H64.0023C64.6977 49.1573 65.5494 48.8877 65.9326 48.547C66.4861 48.0361 66.7416 47.2696 67.6358 43.3381C68.09 41.2658 68.5158 39.4065 68.5725 39.1794L68.6719 38.7962H67.7351C67.2242 38.7962 66.7984 38.8104 66.7984 38.8387C66.7984 38.8671 66.6139 39.8039 66.3868 40.9394C66.1597 42.0606 65.9184 43.2387 65.8616 43.551C65.7906 43.92 65.7339 44.0193 65.7055 43.8348C65.6771 43.6787 65.3932 42.4864 65.0668 41.1665L64.499 38.7962H63.6616C63.1223 38.7962 62.8242 38.8529 62.8242 38.9665Z"
        fill={iconColor}
      />
      <path
        d="M70.7715 43.9038V49.0134H71.6231H72.4747V44.6844V40.3555H73.3263H74.1779V44.6844V49.0134H75.0295H75.8811V43.9038V38.7942H73.3263H70.7715V43.9038Z"
        fill={iconColor}
      />
      <path
        d="M78.5938 43.8896V49.0134H79.4454H80.297V47.1825V45.3657L81.1911 45.2664C82.1137 45.1812 82.5537 44.9399 83.0505 44.2728C83.3202 43.9322 83.3485 43.6767 83.3485 42.087C83.3485 39.2768 82.9937 38.9077 80.2686 38.8226L78.5938 38.7658V43.8896ZM81.3615 40.5542C81.5886 40.7103 81.6453 40.9658 81.6879 41.8032C81.7163 42.3709 81.6737 43.0096 81.5886 43.2225C81.4608 43.5632 81.3615 43.6199 80.8647 43.6199H80.297V41.9877V40.3555H80.6944C80.9215 40.3555 81.2195 40.4406 81.3615 40.5542Z"
        fill={iconColor}
      />
      <path
        d="M86.7316 39.1064C86.689 39.2909 86.3342 41.4767 85.951 43.9748C85.5536 46.4728 85.2129 48.6302 85.1845 48.7579C85.1278 48.985 85.2413 49.0134 85.951 49.0134H86.7884L86.8877 48.1618L86.9871 47.296L87.9239 47.3386L88.8606 47.3812L88.9884 48.1902L89.1019 49.0134H89.9961C90.7767 49.0134 90.8903 48.985 90.8335 48.7579C90.8051 48.6302 90.4645 46.4728 90.0671 43.9748C89.6697 41.4767 89.329 39.2909 89.2864 39.1064C89.2155 38.8084 89.1445 38.7942 88.009 38.7942C86.8736 38.7942 86.8026 38.8084 86.7316 39.1064ZM88.3071 43.7761L88.6051 45.7489H87.9097C87.3135 45.7489 87.2142 45.7064 87.2852 45.4935C87.3135 45.3657 87.4555 44.4432 87.5974 43.4496C87.7394 42.4561 87.8813 41.6754 87.9239 41.718C87.9806 41.7606 88.1368 42.6832 88.3071 43.7761Z"
        fill={iconColor}
      />
      <path
        d="M93.0527 43.9038V49.0134H94.8695C96.9133 49.0134 97.3817 48.8431 97.8217 47.9915C98.3753 46.927 98.1907 44.5283 97.5237 43.9038L97.183 43.5774L97.5095 43.1799C97.9069 42.7116 98.0914 41.6471 97.9495 40.7529C97.6798 39.1774 97.0411 38.8084 94.6566 38.7942H93.0527V43.9038ZM95.863 40.5825C96.1185 40.7671 96.1753 40.9374 96.1753 41.6329C96.1753 42.6122 95.9624 42.9103 95.2385 42.9103H94.7559V41.6329V40.3555H95.1534C95.3663 40.3555 95.6927 40.4548 95.863 40.5825ZM96.1469 44.8122C96.445 45.1102 96.4733 46.8844 96.1895 47.2109C96.0617 47.367 95.7779 47.4522 95.3805 47.4522H94.7559V45.9477V44.429L95.3379 44.5141C95.6643 44.5567 96.0333 44.6844 96.1469 44.8122Z"
        fill={iconColor}
      />
      <path
        d="M101.276 40.1708C101.276 40.9373 101.219 42.7824 101.162 44.2727C101.063 47.0972 100.992 47.452 100.424 47.452C100.183 47.452 100.154 47.5656 100.154 48.3178V49.1694L100.836 49.0843C101.63 48.9707 102.113 48.6585 102.482 48.0198C102.752 47.5798 102.879 45.692 102.979 40.8095C102.993 40.3695 103.007 40.3553 103.632 40.3553H104.27V44.6843V49.0133H105.122H105.974V43.9037V38.7941H103.632H101.29L101.276 40.1708Z"
        fill={iconColor}
      />
      <path
        d="M108.664 43.9038V49.0134H110.864H113.064V48.2328V47.4522H111.716H110.367V46.0328V44.6135H111.432H112.496V43.8328V43.0522H111.432H110.367V41.7038V40.3555H111.716H113.064V39.5748V38.7942H110.864H108.664V43.9038Z"
        fill={iconColor}
      />
      <path
        d="M115.623 43.9038V49.0134H116.475H117.326V46.8844V44.7554H118.178H119.029V46.8844V49.0134H119.881H120.733V43.9038V38.7942H119.881H119.029V40.9942V43.1941H118.178H117.326V40.9942V38.7942H116.475H115.623V43.9038Z"
        fill={iconColor}
      />
      <path
        d="M123.289 43.9094V49.0332L124.24 48.9906L125.191 48.948L126.071 45.8255L126.965 42.7029V45.8539L126.979 49.019H127.76H128.541V43.9094V38.7856L127.59 38.8281L126.639 38.8707L125.972 41.0707C125.603 42.2771 125.205 43.5829 125.092 43.9804C124.893 44.6616 124.879 44.5765 124.865 41.7378L124.85 38.7998H124.07H123.289V43.9094Z"
        fill={iconColor}
      />
      <path
        d="M131.094 43.9038V49.0134H133.365H135.636V48.2328V47.4522H134.287H132.939V46.0328V44.6135H134.003H135.068V43.8328V43.0522H134.003H132.939V41.7038V40.3555H134.287H135.636V39.5748V38.7942H133.365H131.094V43.9038Z"
        fill={iconColor}
      />
      <path
        d="M143.095 38.9645C143.066 39.0639 142.726 41.2212 142.328 43.7619C141.931 46.3025 141.576 48.5167 141.534 48.687C141.463 48.9992 141.505 49.0134 142.3 49.0134H143.137L143.237 48.1618L143.336 47.3102H144.245C145.252 47.3102 145.224 47.2818 145.366 48.3321L145.451 49.0134H146.303C146.984 49.0134 147.14 48.9708 147.14 48.7721C147.14 48.545 145.792 39.958 145.636 39.1774C145.55 38.7942 145.55 38.7942 144.358 38.7942C143.521 38.7942 143.137 38.851 143.095 38.9645ZM144.585 43.3503C144.983 45.8483 144.997 45.7489 144.216 45.7489C143.606 45.7489 143.563 45.7206 143.634 45.4225C143.677 45.2522 143.819 44.3012 143.961 43.3077C144.103 42.3141 144.245 41.5193 144.259 41.5477C144.287 41.5761 144.443 42.3851 144.585 43.3503Z"
        fill={iconColor}
      />
      <path
        d="M149.406 43.9038V49.0134H150.258H151.109V47.5231C151.109 46.2173 151.28 45.3231 151.521 45.3231C151.564 45.3231 151.918 46.1606 152.302 47.1683L152.997 49.0134H153.906C154.587 49.0134 154.814 48.9708 154.757 48.8289C154.729 48.7438 154.232 47.4805 153.664 46.0612L152.642 43.4496L153.664 41.3064C154.232 40.1142 154.715 39.0639 154.757 38.9645C154.814 38.8368 154.601 38.7942 153.962 38.7942H153.082L152.117 40.8096L151.138 42.8393L151.124 40.8096L151.109 38.7942H150.258H149.406V43.9038Z"
        fill={iconColor}
      />
      <path
        d="M156.5 39.5748V40.3555H157.352H158.203V44.6844V49.0134H159.055H159.906V44.6844V40.3555H160.758H161.61V39.5748V38.7942H159.055H156.5V39.5748Z"
        fill={iconColor}
      />
      <path
        d="M163.742 43.9038V49.0134H164.651H165.559L166.411 45.9902L167.276 42.9812V45.9902L167.291 49.0134H168.142H168.994V43.9038V38.7942H168H167.021L166.893 39.2484C166.709 39.9013 165.587 43.6199 165.445 44.0457C165.374 44.2586 165.318 43.2935 165.318 41.5903L165.303 38.7942H164.523H163.742V43.9038Z"
        fill={iconColor}
      />
      <path
        d="M171.547 43.8896V49.0134H173.293C176.046 49.0134 176.515 48.6018 176.515 46.2031C176.515 44.8264 176.302 44.0457 175.876 43.8044C175.663 43.6767 175.677 43.6199 176.004 43.1232C176.33 42.6548 176.373 42.4419 176.373 41.4625C176.373 39.2909 175.918 38.9077 173.293 38.8226L171.547 38.7658V43.8896ZM174.357 40.5825C174.613 40.7671 174.669 40.9374 174.669 41.5903C174.669 42.5554 174.414 42.9103 173.718 42.9103H173.25V41.6329V40.3555H173.647C173.86 40.3555 174.187 40.4548 174.357 40.5825ZM174.627 44.8406C174.868 45.167 174.868 47.0547 174.641 47.2818C174.542 47.3812 174.201 47.4522 173.86 47.4522H173.25V45.9477V44.429L173.846 44.5141C174.173 44.5567 174.527 44.7128 174.627 44.8406Z"
        fill={iconColor}
      />
      <path
        d="M180.145 39.1774C179.975 39.9864 178.641 48.5025 178.641 48.7438C178.641 48.9708 178.754 49.0134 179.407 49.0134H180.188L180.273 48.3321C180.415 47.2818 180.386 47.3102 181.394 47.3102H182.303L182.402 48.1618L182.501 49.0134H183.395H184.304L184.219 48.4741C184.162 48.1902 183.793 45.9051 183.395 43.407L182.672 38.8651L181.451 38.8226L180.23 38.78L180.145 39.1774ZM181.962 45.2806L182.033 45.7489H181.352H180.67L180.94 43.8612C181.096 42.8393 181.252 41.8883 181.295 41.7748C181.366 41.5903 181.479 42.158 181.962 45.2806Z"
        fill={iconColor}
      />
      <path
        d="M186.455 43.9038V49.0134H187.236H188.016L188.031 46.1322L188.045 43.2651L188.385 45.1812C188.57 46.2315 188.783 47.5231 188.868 48.0483L189.038 49.0134H189.861H190.685L190.827 48.1192C190.912 47.6367 191.125 46.2457 191.309 45.0393L191.636 42.8393L191.678 45.9193L191.707 49.0134H192.487H193.268V43.9038V38.78L192.104 38.8226L190.926 38.8651L190.472 41.9167C190.216 43.5915 189.989 45.096 189.975 45.2522C189.947 45.4083 189.677 44.0173 189.379 42.158L188.825 38.7942H187.647H186.455V43.9038Z"
        fill={iconColor}
      />
      <path
        d="M195.967 43.9094V49.0332L196.847 48.9906L197.727 48.948L198.053 47.8126C198.238 47.1881 198.635 45.7829 198.947 44.69L199.501 42.7029V45.8539L199.515 49.019H200.296H201.076V43.9094V38.7856L200.14 38.8281L199.203 38.8707L198.706 40.432C198.436 41.2836 198.067 42.5042 197.883 43.1287L197.542 44.2642V41.5249L197.528 38.7998H196.747H195.967V43.9094Z"
        fill={iconColor}
      />
    </svg>
  );
}
