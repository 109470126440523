import { PersistConfig } from "redux-persist";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../store/configureStore";
import { AppEnvironment, AppState, ConnectionState } from "../dto/ApplicationDTO";

export const applicationReducerPersistConfig: Partial<PersistConfig<Store.AppReducerState>> = {
  whitelist: ["firstStart", "showDevelopmentMenu", "environment", "themeMode"],
};

const initialState: Store.AppReducerState = {
  showDevelopmentMenu: false,
  state: AppState.Undetermined,
  environment: AppEnvironment.Development,
  serverConnectionState: ConnectionState.Undetermined,
  internetConnectionState: ConnectionState.Undetermined,
  financeSecurityBackdropShown: false,
};

export const applicationSlice = createSlice({
  initialState,
  name: "application",
  reducers: {
    changeServerConnectionState: (state, action: PayloadAction<ConnectionState>) => {
      state.serverConnectionState = action.payload;
    },
    changeInternetConnectionState: (state, action: PayloadAction<ConnectionState>) => {
      state.internetConnectionState = action.payload;
    },
    changeAppState: (state, action: PayloadAction<Application.AppStateType>) => {
      state.state = action.payload;
    },
    changeShowDevelopmentMenu: (state, action: PayloadAction<boolean>) => {
      state.showDevelopmentMenu = action.payload;
    },
    changeEnvironment: (state, action: PayloadAction<Application.AppEnvironmentType>) => {
      state.environment = action.payload;
    },
    changeStartRedirectUrl: (state, action: PayloadAction<string | undefined>) => {
      state.startRedirectUrl = action.payload;
    },
    changeFinanceSecurityBackdropShown: (state, action: PayloadAction<boolean>) => {
      state.financeSecurityBackdropShown = action.payload;
    },
  },
});

/**
 * Actions
 * */
export const {
  changeAppState,
  changeEnvironment,
  changeStartRedirectUrl,
  changeShowDevelopmentMenu,
  changeServerConnectionState,
  changeInternetConnectionState,
  changeFinanceSecurityBackdropShown,
} = applicationSlice.actions;

/**
 * Selectors
 * */
export function applicationSelector({ application }: RootState): Application.AppStateType {
  return application.state;
}

export function serverConnectionSelector({
  application,
}: RootState): Application.ConnectionStateType {
  return application.serverConnectionState;
}

export function internetConnectionSelector({
  application,
}: RootState): Application.ConnectionStateType {
  return application.internetConnectionState;
}

export function showDevelopmentMenuSelector({ application }: RootState): boolean {
  return application.showDevelopmentMenu;
}

export function environmentSelector({ application }: RootState): Application.AppEnvironmentType {
  return application.environment;
}

/**
 * Reducer
 * */
export default applicationSlice.reducer;
