import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { makeStyles } from "../../theme/Theme";
import { useColor } from "../../hooks/useColor";
import { AppRoutes } from "../../constants/AppRoutes";
import { useLazyProfileQuery } from "../../api/profileApi";
import { isAuthorizedSelector } from "../../slices/authSlice";
import { LogoWithTextVerticalcon } from "../../components/icons/LogoWithTextVerticalcon";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";

interface StylesProps {
  readonly state: boolean;
}

const useStyles = makeStyles<StylesProps>((theme) => ({
  root: { flex: 1, alignItems: "center", justifyContent: "center", display: "flex" },
  icon: {
    transition: theme.transitions.create("opacity"),
    opacity: (props: StylesProps) => (props.state ? 1 : 0.5),
  },
}));

export function SplashPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loadProfile] = useLazyProfileQuery();

  const isAuthorized = useShallowEqualSelector(isAuthorizedSelector);

  const [stateOpacity, setStateOpacity] = useState(false);

  const classes = useStyles({ state: stateOpacity });

  const logoColor = useColor({
    dark: (palette) => palette.common.white,
    light: (palette) => palette.common.black,
  });

  useEffect(() => {
    const logoTimeout = setTimeout(() => setStateOpacity(true), 2000);

    const redirectTimeout = setTimeout(() => {
      if (isAuthorized) {
        loadProfile()
          .unwrap()
          .then(() => navigate(AppRoutes.DashboardBriefcaseLink))
          .catch(() => navigate(AppRoutes.StartAuth));
      } else {
        navigate(AppRoutes.StartAuth);
      }
    }, 3000);

    return () => {
      clearTimeout(logoTimeout);
      clearTimeout(redirectTimeout);
    };
  }, [dispatch, isAuthorized, loadProfile, navigate]);

  return (
    <div className={classes.root}>
      <LogoWithTextVerticalcon className={classes.icon} color={logoColor} />
    </div>
  );
}
