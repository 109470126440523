import React, { useLayoutEffect, useRef } from "react";
import cx from "classnames";
import TextField, { TextFieldProps } from "@mui/material/TextField";

import { makeStyles } from "../../theme/Theme";
import { Breakpoints } from "../../dto/ApplicationDTO";
import { limitByLength, validateField } from "../../utils/FieldUtils";

interface StylesProps {
  readonly disabled?: boolean;
  readonly inputTextColor?: string;
}

const useStyles = makeStyles<StylesProps>((theme) => ({
  root: (props: StylesProps) => ({
    "&.MuiTextField-root": {
      "& > .MuiInputBase-root": {
        opacity: props.disabled ? 0.5 : 1,
        overflow: "hidden",
        backgroundColor: theme.palette.back.main,
        borderRadius: theme.typography.pxToRem(20),
        "&.MuiInputBase-multiline": {
          padding: 0,
        },
        "& > .MuiOutlinedInput-notchedOutline": {
          transition: "border-color 270ms ease-in-out",
          borderRadius: theme.typography.pxToRem(20),
          borderColor: theme.palette.common.transparent,
        },
        "& > .MuiInputBase-input": {
          height: "20px",
          color: (props) => props.inputTextColor || theme.palette.gary.main,
          padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(23)}`,

          fontSize: theme.typography.pxToRem(16),
          lineHeight: theme.typography.pxToRem(19),
          fontWeight: 590,

          borderColor: theme.palette.common.transparent,

          [theme.breakpoints.only(Breakpoints.Tablet)]: {
            fontSize: theme.typography.pxToRem(14),
            lineHeight: theme.typography.pxToRem(17),
          },

          "&:focus + .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.grape.main,
            borderWidth: theme.typography.pxToRem(2),
          },
        },
        "&:hover:not(.Mui-disabled)": {
          "& > .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.grape.main,
            borderWidth: theme.typography.pxToRem(2),

            [theme.breakpoints.down(Breakpoints.Tablet)]: {
              borderColor: theme.palette.common.transparent,
            },
          },
        },
      },
    },
  }),
}));

export interface TextInputProps extends Omit<TextFieldProps, "value"> {
  readonly value?: string;
  maxlength?: string | number;
  inputCharactersType?: Survey.InputCharactersType;
  readonly inputTextColor?: string;
}

export function TextInput({
  className,
  inputTextColor,
  disabled,
  maxlength,
  inputCharactersType,
  ...props
}: TextInputProps) {
  const classes = useStyles({ disabled, inputTextColor });

  const refInput = useRef<HTMLInputElement>();

  useLayoutEffect(() => {
    if (!refInput.current) return;
    const input = refInput.current;

    if (maxlength) limitByLength(input, maxlength.toString());
  }, []);

  return (
    <TextField
      inputRef={refInput}
      autoCorrect="off"
      variant="outlined"
      autoComplete="off"
      autoCapitalize="off"
      onKeyUp={(e) => (inputCharactersType ? validateField(e, inputCharactersType) : undefined)}
      disabled={disabled}
      {...props}
      inputProps={{
        ...props.inputProps,
        inputMode: inputCharactersType === "only-numbers" ? "numeric" : props.inputProps?.inputMode,
      }}
      className={cx(classes.root, className)}
    />
  );
}
