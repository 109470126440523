import React from "react";
import { Stack, IconButton, Typography, Snackbar as MuiSnackbar, Box } from "@mui/material";
import { CloseIcon } from "../icons/CloseIcon";

import { useTheme, makeStyles } from "../../theme/Theme";

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    flexShrink: 0,
    width: 30,
    height: 30,
  },
}));

export interface SnackbarProps {
  title: string;
  content: string;
  variant?: "info";
}

export interface SnackbarData extends SnackbarProps {
  open: boolean;
}

export interface NotificationContextProps extends SnackbarData {
  closeSnackbar: () => void;
  openSnackbar: (data: SnackbarProps) => void;
}

export const SnackbarInitialState: SnackbarData = {
  open: false,
  title: "",
  content: "",
  variant: "info",
};

export const Snackbar: React.FC<NotificationContextProps> = React.forwardRef(
  ({ title, closeSnackbar, content, open, ...other }, ref) => {
    const theme = useTheme();
    const classes = useStyles();

    return (
      <MuiSnackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={open}
        onClose={closeSnackbar}
      >
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="text3">{title}</Typography>
            <IconButton
              sx={{ width: theme.typography.pxToRem(24), height: theme.typography.pxToRem(24) }}
              onClick={closeSnackbar}
            >
              <CloseIcon color={theme.palette.total.main} className={classes.closeIcon} />
            </IconButton>
          </Stack>

          <Typography width={333} variant="text2">
            {content}
          </Typography>
        </Box>
      </MuiSnackbar>
    );
  },
);
