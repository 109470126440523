import React from "react";
import { useField } from "formik";

import { CodeInput, CodeInputProps } from "../ui/CodeInput";
import { FormControl, FormControlProps } from "./FormControl";

interface Props extends Omit<FormControlProps, "children" | "error"> {
  readonly name: string;
  readonly showErrors?: boolean;
  readonly inputProps?: Omit<CodeInputProps, "onChange">;
  isLoad?: boolean;
}

export function CodeField({ name, showErrors = true, isLoad, inputProps, ...props }: Props) {
  const [, meta, helpers] = useField(name);

  const error = meta.touched && showErrors ? meta.error : undefined;

  return (
    <FormControl {...props} error={error}>
      <CodeInput
        {...inputProps}
        isLoad={isLoad}
        onChange={(value) => helpers.setValue(value)}
        onFocus={() => {
          if (inputProps?.onFocus) {
            inputProps.onFocus();
          }

          helpers.setTouched(true);
        }}
      />
    </FormControl>
  );
}
