import React from "react";

import { useColor } from "../../hooks/useColor";

export function LogoWithTextVerticalcon({ color, ...props }: Application.IconProps) {
  const iconColor = useColor({ light: (palette) => color || palette.gary.main });

  return (
    <svg
      width="121"
      fill="none"
      height="120"
      viewBox="0 0 121 120"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M44.8167 6.05761C44.4724 6.13959 44.1609 6.36912 43.9314 6.71342C43.7428 6.97574 43.7428 7.02493 43.7428 9.42682V11.8779H45.2184H46.694V10.4433V9.00875H68.0077H89.3215V30.3225V51.6363H68.0077H46.694V36.1428V20.6493H45.2184H43.7428V37.2495C43.7428 55.5055 43.7018 54.1939 44.2347 54.4644C44.4478 54.571 47.1284 54.5874 68.0077 54.5874C88.887 54.5874 91.5676 54.571 91.7807 54.4644C92.3136 54.1857 92.2726 56.2023 92.2726 30.3225C92.2726 4.59844 92.3136 6.51667 91.8053 6.17237C91.584 6.01662 91.0184 6.01662 68.3356 6.00023C55.5556 6.00023 44.9725 6.02482 44.8167 6.05761Z"
        fill={iconColor}
      />
      <path
        d="M65.8798 20.6834C65.8798 20.6998 65.1994 24.979 64.3632 30.1926C63.5271 35.4063 62.8467 39.7264 62.8467 39.792C62.8467 39.8986 63.0598 39.915 64.2649 39.915H65.6831L65.9208 38.1935L66.1585 36.472H67.9866H69.8064L69.8556 36.7343C69.9376 37.2344 70.3065 39.7428 70.3065 39.8248C70.3065 39.8822 70.782 39.915 71.7411 39.915C72.8314 39.915 73.1757 39.8904 73.1757 39.8084C73.1757 39.7592 72.4789 35.4309 71.6345 30.2008L70.0934 20.6916L67.9866 20.667C66.8307 20.6588 65.8798 20.6588 65.8798 20.6834ZM68.667 28.9384C69.0277 31.3485 69.3474 33.4143 69.372 33.5372L69.4212 33.7668H68.0194C67.2488 33.7668 66.6176 33.7422 66.6176 33.7012C66.6094 33.5864 67.9702 24.5117 67.9866 24.5363C68.003 24.5445 68.3063 26.5283 68.667 28.9384Z"
        fill={iconColor}
      />
      <path
        d="M28.8512 23.7641C28.2938 24.1002 28.3347 22.2557 28.3347 44.8893C28.3347 65.0554 28.3347 65.3669 28.4987 65.6292C28.5807 65.7768 28.7774 65.9571 28.925 66.0391C29.1873 66.1784 30.1136 66.1866 49.6649 66.1866H70.1343L70.3556 66.0145C70.4786 65.9243 70.6589 65.744 70.7491 65.621C70.9131 65.3997 70.9213 65.2931 70.9459 61.4238L70.9704 57.4562H69.4457H67.9291V60.3254V63.1945H49.6075H31.2859V44.8729V26.5513H36.0815H40.8771V25.0757V23.6001H34.9994C29.3103 23.6001 29.1053 23.6083 28.8512 23.7641Z"
        fill={iconColor}
      />
      <path
        d="M83.079 79.6854C81.2215 79.8431 79.9336 80.7981 79.3641 82.4541C78.9961 83.5054 78.9523 84.3728 78.9874 90.7599C79.0224 96.0869 79.0399 96.7703 79.1714 97.2434C79.846 99.5652 81.3004 100.608 83.885 100.617C85.4446 100.617 86.5923 100.187 87.4247 99.2848C88.3884 98.2422 88.6075 97.4449 88.6688 94.6763L88.7214 92.6436H87.1268H85.5409V94.3784C85.5409 96.4461 85.4533 96.8842 84.9539 97.2609C84.6911 97.4624 84.5334 97.5063 84.0164 97.5325C83.2542 97.5588 82.8074 97.3485 82.527 96.8053C82.343 96.4636 82.343 96.341 82.3167 90.4971C82.2992 87.2203 82.3167 84.3378 82.343 84.0837C82.4394 83.2601 82.9563 82.7432 83.7273 82.6731C84.3494 82.6205 84.7699 82.7695 85.0941 83.1374C85.4709 83.5668 85.5409 83.9347 85.5409 85.4768V86.8611H87.1268H88.7214L88.6688 85.2139C88.6075 82.822 88.3008 81.832 87.3283 80.8507C86.3908 79.9132 84.9539 79.519 83.079 79.6854Z"
        fill={iconColor}
      />
      <path
        d="M21.1328 79.8705C21.1328 79.8881 20.3881 84.4966 19.4856 90.1215L17.8473 100.355H19.3542C20.721 100.355 20.87 100.337 20.9138 100.197C20.9313 100.118 21.0627 99.286 21.1941 98.3572L21.4395 96.675H23.3757H25.3033L25.5573 98.4011C25.6975 99.3561 25.829 100.188 25.8465 100.241C25.8815 100.329 26.3021 100.355 27.555 100.355C29.0882 100.355 29.2109 100.346 29.1758 100.197C29.1495 100.118 28.4048 95.501 27.5199 89.955L25.9166 79.853H23.5334C22.2192 79.853 21.1416 79.8618 21.1328 79.8705ZM24.7951 93.7487C24.7776 93.775 24.1117 93.775 23.3232 93.7662L21.8775 93.7399L22.5522 89.2716C22.9201 86.8096 23.2531 84.6017 23.2881 84.3652C23.3495 83.9884 23.4546 84.5755 24.0942 88.8248C24.4972 91.5145 24.8126 93.7312 24.7951 93.7487Z"
        fill={iconColor}
      />
      <path
        d="M34.1059 90.1039V100.355H37.2425C40.712 100.355 41.0712 100.311 42.0175 99.8029C43.0075 99.2772 43.6384 98.4361 43.9888 97.1832C44.2079 96.3684 44.1991 92.855 43.9713 92.0315C43.6471 90.8574 43.0163 89.99 42.219 89.6133L41.9123 89.4644L42.3679 89.1314C42.9112 88.7459 43.3054 88.1677 43.5858 87.3879C43.761 86.871 43.7785 86.6607 43.7873 85.0223C43.7873 83.0685 43.7172 82.6392 43.2353 81.7543C42.9199 81.1672 42.184 80.5189 41.5707 80.2911C40.6069 79.9231 39.9235 79.8618 36.9358 79.853H34.1059V90.1039ZM39.2138 82.9108C39.7483 82.9984 40.0549 83.2262 40.309 83.7168C40.5018 84.0848 40.5105 84.1812 40.4842 85.5918C40.4579 87.2915 40.3966 87.4843 39.7833 87.8961C39.4504 88.1151 39.3365 88.1326 38.4253 88.1677L37.4352 88.1939V85.5129V82.8319L38.1186 82.8407C38.4866 82.8407 38.986 82.8757 39.2138 82.9108ZM39.9673 91.4357C40.2652 91.5934 40.4229 91.7511 40.5894 92.0753C40.7996 92.4958 40.8084 92.5484 40.8084 94.3971C40.8084 96.6137 40.7471 96.8327 40.0637 97.1744C39.722 97.3497 39.5468 97.376 38.5567 97.376H37.4352V94.2481V91.129L38.5041 91.1816C39.3803 91.2254 39.6431 91.2692 39.9673 91.4357Z"
        fill={iconColor}
      />
      <path
        d="M49.616 90.1039V100.355H51.2368H52.8577V91.5934V82.8319H55.4861H58.1146V81.3425V79.853H53.8653H49.616V90.1039Z"
        fill={iconColor}
      />
      <path
        d="M62.8757 80.0446C62.902 80.1585 63.9096 83.7332 65.1099 87.9912L67.2915 95.7364L67.1689 96.1657C66.8973 97.0594 66.2401 97.4624 65.0573 97.4624H64.4178V98.9518V100.441H65.4078C67.2127 100.441 68.4656 99.9944 69.3329 99.057C69.92 98.4261 70.1916 97.918 70.542 96.8491C70.7873 96.1044 74.4058 80.2987 74.4058 79.9833C74.4058 79.8694 74.143 79.8518 72.7061 79.8518H71.0064L70.9012 80.3074C70.174 83.6806 69.5082 87.08 69.2015 88.9638C68.9912 90.2429 68.7985 91.2242 68.7722 91.1541C68.7459 91.084 68.5006 89.9538 68.2378 88.6571C67.9662 87.3517 67.3791 84.8546 66.9323 83.0936L66.1262 79.8956L64.4703 79.8694L62.8232 79.8518L62.8757 80.0446Z"
        fill={iconColor}
      />
      <path
        d="M93.1623 81.3425V82.8319H94.8269H96.4916V91.5934V100.355H98.1563H99.821V91.5934V82.8319H101.486H103.15V81.3425V79.853H98.1563H93.1623V81.3425Z"
        fill={iconColor}
      />
      <path
        d="M17.8125 103.699C17.8125 103.752 18.1104 104.856 18.4784 106.153C18.8726 107.554 19.118 108.571 19.0829 108.676C19.0479 108.799 18.8902 108.895 18.6361 108.956L18.2506 109.061V109.526V109.99H18.5397C18.969 109.99 19.4947 109.824 19.7313 109.613C20.073 109.298 20.2307 108.825 20.7826 106.398C21.063 105.119 21.3258 103.971 21.3609 103.831L21.4222 103.594H20.844C20.5285 103.594 20.2657 103.603 20.2657 103.621C20.2657 103.638 20.1518 104.216 20.0116 104.917C19.8714 105.609 19.7225 106.337 19.6874 106.529C19.6436 106.757 19.6086 106.818 19.5911 106.705C19.5735 106.608 19.3983 105.872 19.1968 105.057L18.8463 103.594H18.3294C17.9965 103.594 17.8125 103.629 17.8125 103.699Z"
        fill={iconColor}
      />
      <path
        d="M22.7205 106.749V109.903H23.2462H23.7719V107.231V104.558H24.2976H24.8233V107.231V109.903H25.349H25.8747V106.749V103.595H24.2976H22.7205V106.749Z"
        fill={iconColor}
      />
      <path
        d="M27.5443 106.74V109.903H28.0699H28.5956V108.773V107.651L29.1476 107.59C29.7171 107.537 29.9887 107.388 30.2954 106.977C30.4618 106.766 30.4793 106.609 30.4793 105.627C30.4793 103.893 30.2603 103.665 28.5781 103.612L27.5443 103.577V106.74ZM29.2527 104.681C29.3929 104.777 29.428 104.935 29.4543 105.452C29.4718 105.803 29.4455 106.197 29.3929 106.328C29.3141 106.538 29.2527 106.574 28.9461 106.574H28.5956V105.566V104.558H28.8409C28.9811 104.558 29.1651 104.611 29.2527 104.681Z"
        fill={iconColor}
      />
      <path
        d="M32.5756 103.787C32.5493 103.901 32.3303 105.25 32.0937 106.793C31.8484 108.335 31.6381 109.666 31.6206 109.745C31.5856 109.885 31.6557 109.903 32.0937 109.903H32.6107L32.672 109.377L32.7333 108.843L33.3116 108.869L33.8898 108.895L33.9687 109.395L34.0388 109.903H34.5907C35.0726 109.903 35.1427 109.885 35.1077 109.745C35.0901 109.666 34.8799 108.335 34.6345 106.793C34.3892 105.25 34.179 103.901 34.1527 103.787C34.1089 103.603 34.0651 103.595 33.3641 103.595C32.6632 103.595 32.6194 103.603 32.5756 103.787ZM33.5481 106.67L33.7321 107.888H33.3028C32.9348 107.888 32.8735 107.861 32.9173 107.73C32.9348 107.651 33.0224 107.082 33.1101 106.468C33.1977 105.855 33.2853 105.373 33.3116 105.399C33.3466 105.426 33.443 105.995 33.5481 106.67Z"
        fill={iconColor}
      />
      <path
        d="M36.4729 106.749V109.903H37.5943C38.856 109.903 39.1451 109.798 39.4167 109.272C39.7584 108.615 39.6445 107.134 39.2327 106.749L39.0225 106.547L39.224 106.302C39.4693 106.013 39.5832 105.356 39.4956 104.804C39.3291 103.831 38.9348 103.603 37.4629 103.595H36.4729V106.749ZM38.2076 104.699C38.3654 104.812 38.4004 104.918 38.4004 105.347C38.4004 105.951 38.269 106.135 37.8221 106.135H37.5243V105.347V104.558H37.7696C37.901 104.558 38.1025 104.62 38.2076 104.699ZM38.3829 107.309C38.5669 107.493 38.5844 108.589 38.4092 108.79C38.3303 108.887 38.1551 108.939 37.9098 108.939H37.5243V108.01V107.073L37.8835 107.126C38.085 107.152 38.3128 107.231 38.3829 107.309Z"
        fill={iconColor}
      />
      <path
        d="M41.5471 104.445C41.5471 104.919 41.5121 106.058 41.477 106.978C41.4157 108.721 41.3719 108.94 41.0214 108.94C40.8725 108.94 40.855 109.01 40.855 109.475V110L41.2755 109.948C41.7662 109.878 42.0641 109.685 42.2919 109.291C42.4583 109.019 42.5372 107.854 42.5985 104.84C42.6073 104.568 42.616 104.559 43.0015 104.559H43.3958V107.232V109.904H43.9215H44.4472V106.75V103.596H43.0015H41.5559L41.5471 104.445Z"
        fill={iconColor}
      />
      <path
        d="M46.1099 106.749V109.903H47.4679H48.8259V109.421V108.939H47.9936H47.1612V108.063V107.187H47.8183H48.4755V106.705V106.223H47.8183H47.1612V105.391V104.558H47.9936H48.8259V104.076V103.595H47.4679H46.1099V106.749Z"
        fill={iconColor}
      />
      <path
        d="M50.4036 106.749V109.903H50.9292H51.4549V108.589V107.274H51.9806H52.5063V108.589V109.903H53.032H53.5577V106.749V103.595H53.032H52.5063V104.953V106.311H51.9806H51.4549V104.953V103.595H50.9292H50.4036V106.749Z"
        fill={iconColor}
      />
      <path
        d="M55.1333 106.753V109.916L55.7203 109.889L56.3073 109.863L56.8505 107.936L57.4025 106.008V107.953L57.4112 109.907H57.8931H58.375V106.753V103.59L57.788 103.616L57.201 103.642L56.7892 105.001C56.5614 105.745 56.3161 106.551 56.246 106.797C56.1233 107.217 56.1145 107.165 56.1058 105.412L56.097 103.599H55.6151H55.1333V106.753Z"
        fill={iconColor}
      />
      <path
        d="M59.9562 106.749V109.903H61.3581H62.7599V109.421V108.939H61.9276H61.0952V108.063V107.187H61.7523H62.4095V106.705V106.223H61.7523H61.0952V105.391V104.558H61.9276H62.7599V104.077V103.595H61.3581H59.9562V106.749Z"
        fill={iconColor}
      />
      <path
        d="M67.3581 103.7C67.3405 103.761 67.1303 105.093 66.8849 106.661C66.6396 108.229 66.4206 109.596 66.3943 109.701C66.3505 109.894 66.3768 109.903 66.8674 109.903H67.3844L67.4457 109.377L67.507 108.852H68.0677C68.6898 108.852 68.6723 108.834 68.7599 109.482L68.8125 109.903H69.3382C69.7587 109.903 69.8551 109.877 69.8551 109.754C69.8551 109.614 69.0227 104.313 68.9264 103.831C68.8738 103.595 68.8738 103.595 68.1378 103.595C67.6209 103.595 67.3844 103.63 67.3581 103.7ZM68.278 106.407C68.5233 107.949 68.5321 107.888 68.0502 107.888C67.6735 107.888 67.6472 107.87 67.691 107.686C67.7173 107.581 67.8049 106.994 67.8925 106.381C67.9801 105.767 68.0677 105.277 68.0765 105.294C68.094 105.312 68.1904 105.811 68.278 106.407Z"
        fill={iconColor}
      />
      <path
        d="M71.2583 106.749V109.903H71.784H72.3097V108.983C72.3097 108.177 72.4148 107.625 72.5637 107.625C72.59 107.625 72.8091 108.142 73.0456 108.764L73.4749 109.903H74.0357C74.4562 109.903 74.5964 109.877 74.5614 109.789C74.5438 109.736 74.2372 108.957 73.8867 108.081L73.2559 106.468L73.8867 105.145C74.2372 104.409 74.5351 103.761 74.5614 103.7C74.5964 103.621 74.465 103.595 74.0707 103.595H73.5275L72.9317 104.839L72.3272 106.092L72.3184 104.839L72.3097 103.595H71.784H71.2583V106.749Z"
        fill={iconColor}
      />
      <path
        d="M75.6379 104.076V104.558H76.1636H76.6893V107.231V109.903H77.215H77.7407V107.231V104.558H78.2664H78.7921V104.076V103.595H77.215H75.6379V104.076Z"
        fill={iconColor}
      />
      <path
        d="M80.1051 106.749V109.903H80.6658H81.2265L81.7522 108.037L82.2867 106.179V108.037L82.2954 109.903H82.8211H83.3468V106.749V103.595H82.7335H82.129L82.0501 103.875C81.9362 104.278 81.2441 106.574 81.1564 106.836C81.1126 106.968 81.0776 106.372 81.0776 105.321L81.0688 103.595H80.5869H80.1051V106.749Z"
        fill={iconColor}
      />
      <path
        d="M84.9278 106.74V109.903H86.0055C87.7052 109.903 87.9943 109.649 87.9943 108.168C87.9943 107.318 87.8629 106.836 87.6001 106.687C87.4686 106.609 87.4774 106.574 87.6789 106.267C87.8804 105.978 87.9067 105.846 87.9067 105.242C87.9067 103.901 87.6263 103.665 86.0055 103.612L84.9278 103.577V106.74ZM86.6626 104.699C86.8203 104.812 86.8553 104.918 86.8553 105.321C86.8553 105.916 86.6976 106.135 86.2683 106.135H85.9792V105.347V104.558H86.2245C86.3559 104.558 86.5574 104.62 86.6626 104.699ZM86.829 107.327C86.978 107.529 86.978 108.694 86.8378 108.834C86.7765 108.895 86.5662 108.939 86.3559 108.939H85.9792V108.01V107.073L86.3472 107.126C86.5487 107.152 86.7677 107.248 86.829 107.327Z"
        fill={iconColor}
      />
      <path
        d="M90.2338 103.831C90.1287 104.331 89.3051 109.587 89.3051 109.736C89.3051 109.877 89.3752 109.903 89.7782 109.903H90.2601L90.3127 109.482C90.4003 108.834 90.3828 108.852 91.0048 108.852H91.5656L91.6269 109.377L91.6882 109.903H92.2402H92.8009L92.7484 109.57C92.7133 109.395 92.4855 107.984 92.2402 106.442L91.7934 103.638L91.0399 103.612L90.2864 103.586L90.2338 103.831ZM91.3553 107.599L91.3991 107.888H90.9785H90.558L90.7245 106.722C90.8208 106.092 90.9172 105.505 90.9435 105.435C90.9873 105.321 91.0574 105.671 91.3553 107.599Z"
        fill={iconColor}
      />
      <path
        d="M94.123 106.749V109.903H94.6049H95.0868L95.0956 108.124L95.1043 106.354L95.3146 107.537C95.4285 108.186 95.5599 108.983 95.6125 109.307L95.7176 109.903H96.2258H96.734L96.8216 109.351C96.8741 109.053 97.0056 108.194 97.1195 107.45L97.321 106.092L97.3473 107.993L97.3648 109.903H97.8467H98.3285V106.749V103.586L97.6101 103.612L96.8829 103.638L96.6025 105.522C96.4448 106.556 96.3046 107.485 96.2959 107.581C96.2784 107.677 96.1119 106.819 95.9279 105.671L95.5862 103.595H94.859H94.123V106.749Z"
        fill={iconColor}
      />
      <path
        d="M99.9963 106.753V109.916L100.539 109.889L101.083 109.863L101.284 109.162C101.398 108.777 101.643 107.909 101.836 107.235L102.178 106.008V107.953L102.187 109.907H102.669H103.15V106.753V103.59L102.572 103.616L101.994 103.642L101.687 104.606C101.521 105.132 101.293 105.885 101.179 106.271L100.969 106.972V105.281L100.96 103.599H100.478H99.9963V106.753Z"
        fill={iconColor}
      />
    </svg>
  );
}
