import { ComponentThemeOverride } from "@mui/material/styles";

export const MuiPaper: ComponentThemeOverride = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.total.main,
      padding: theme.typography.pxToRem(20),
      borderRadius: theme.typography.pxToRem(20),
    }),
  },
};
