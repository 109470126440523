import { createApi } from "@reduxjs/toolkit/query/react";

import { ApiRoutes } from "../constants/ApiRoutes";
import { fetchQuery } from "../helpers/ApiHelpers";

enum StrategyApiTypes {
  StrategyPromotList = "strategy-promo-list",
}

export const strategyApi = createApi({
  reducerPath: "strategyApi",
  baseQuery: fetchQuery(),
  tagTypes: [StrategyApiTypes.StrategyPromotList],
  endpoints: (builder) => ({
    strategyPromoList: builder.query<
      Application.ResponseProps<Strategy.StrategyPromoProps[]>,
      void
    >({
      query: () => ApiRoutes.StrategyPromoList,
      providesTags: [StrategyApiTypes.StrategyPromotList],
    }),
  }),
});

export const { useStrategyPromoListQuery } = strategyApi;
