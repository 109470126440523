import "./theme/styles/main.scss";

import React from "react";
import { createRoot } from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import { AppContainer } from "./containers/AppContainer";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const container = document.getElementById("root");

if (container) {
  const root = createRoot(container);

  // @ts-ignore
  root.render(<AppContainer />);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
