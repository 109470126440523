import Stack from "@mui/material/Stack";
import React, { ReactNode } from "react";
import Typography from "@mui/material/Typography";

import { useTheme } from "../../theme/Theme";

export interface FormControlProps {
  readonly error?: string;
  readonly className?: string;
  readonly children: ReactNode;
}

export function FormControl({ error, className, children }: FormControlProps) {
  const theme = useTheme();

  return (
    <Stack direction="column" spacing={1} className={className}>
      {children}

      {!!error && (
        <Stack height={theme.pxToRem(24)}>
          <Typography color={theme.palette.sparrow.main} variant="text1">
            {error}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
